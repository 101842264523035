import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Header, Footer } from '../components/PageFrame'
import "../style/pages/post.css"
import { useHistory } from 'react-router-dom'
import { useStore } from "../util"
import { deletePost, getPost, useApi } from "../util/axios-api"
import { Modal, Tooltip, Button, Skeleton, message } from 'antd';
import 'braft-editor/dist/index.css'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { lngSetting, settingsMap, settingsMapName } from '../config';
import i18next from 'i18next';
const { confirm } = Modal;


const Post = ({ match }) => {
  const history = useHistory()
  const area = match.path.split("/")[1];
  const { t, i18n } = useTranslation("post")
  const { lng, isLogin } = useStore()
  const { id } = match.params
  const [data, setData] = useState("")
  const [loading, setLoading] = useState(false)
  const [link, setLink] = useState("")
  const api = useApi()

  const loadData = async (id) => {
    setLoading(true)
    const res = await getPost(api, id)
    const { categories, data, date, title: { rendered: title }, brother } = res.data
    setLink(brother)
    if (brother) {
      if (i18n.language !== lngSetting.category[categories]) {
        const brotherPage = await getPost(api, brother);
        const { categories: brotherCategories } = brotherPage.data
        if (i18n.language === lngSetting.category[brotherCategories]) {
          history.replace("/post/" + brother)
          setLoading(false)
          return
        }
      }
    }
    setData({ categories, data, date, title })
    setLoading(false)
  }

  useEffect(() => {
    loadData(id)
    i18n.changeLanguage(lng)
  }, [id])

  const showConfirm = () => {
    confirm({
      title: t("Do you want to delete this post?"),
      icon: <ExclamationCircleOutlined />,
      okText: t("Confirm"),
      cancelText: t("Cancel"),
      onOk() {
        deletePost(api, id).then(() => { history.replace("/post/list");})
      },
      onCancel() {
      },
    });
  }

  return (
    <div className="mw-1200">
      <Header area={area} history={history} func={link ? () => { history.replace("/post/" + link) } : undefined}></Header>
      <section className="content-area">
        <div className="content">
          <header className="app-bg">
            <div className="app-mask">
              <div className="title" >
                <div className="title-words">
                  {loading ? "loading..." : data.title}
                </div>
                {loading ? <div className="time">loading...</div> :
                  <div className="time">
                    {i18n.language === "en" && data.date?.split("T")[0]}
                    {i18n.language === "zh" && data.date?.split("T")[0]}
                  </div>
                }
              </div>
            </div>
          </header>

          <nav>
            {Array.isArray(data.categories) &&
              <Tooltip title={t("click to get more")} onClick={() => { history.push("/post/list/" + settingsMap.category[data.categories[0]]) }}>
                <Button>{settingsMapName.category[data.categories[0]]}</Button>
              </Tooltip>
            }
            {isLogin ?
              <div className="toolbox">
                <Button style={{ marginRight: "6px" }} onClick={() => history.push("/post/editor/" + id)}>{t("Edit")}</Button>
                <Button onClick={showConfirm}>{t("Delete")}</Button>
              </div> :
              <div></div>
            }
          </nav>


          <div className="main-content">
            {
              loading ? <Skeleton></Skeleton> : <div dangerouslySetInnerHTML={{ __html: data.data }} />
            }
          </div>
        </div>
      </section>
      <Footer></Footer>
    </div>

  )
}
export default Post;
