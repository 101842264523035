import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Header, Footer } from '../components/PageFrame'
import "../style/pages/post-editor.css"
import { useHistory } from 'react-router-dom'
import { useStore } from "../util"
import Editor from "../components/RichTextEditor"
import { getPost, postPost, useApi } from "../util/axios-api";
import { Form, Select, Input, Button, Spin } from 'antd';
import { idSettings } from '../config';

const EditorForm = ({ match }) => {
  const api = useApi()
  const history = useHistory()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const id = match.params.id
  const onFinish = (values) => {
    const res = { ...values, status: "publish" }
    postPost(api, res, id).then((item) => {
      const { data: { id }, success } = item
      if (success) history.push("/post/" + id)
    })
  }
  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 32 },
  };
  const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
  };

  const loadForm = async (id) => {
    setLoading(true)
    const { data } = await getPost(api, id)
    form.setFieldsValue({
      title: data.title.rendered,
      categories: data.categories[0] + "",
      figure: data.figure,
      content: data.content.rendered.slice(3, -5),
      data: data.data,
      brother: data.brother,
    })
    setLoading(false)
  }

  useEffect(() => {
    if (id) loadForm(id)
  }, [])

  return (
    <div className="editor-form-wrapper mw-1200">
      <Spin spinning={loading}>
        <Form form={form} labelAlign="right" {...layout} onFinish={onFinish} className="form">

          <Form.Item name="title" label="文章标题" rules={[{ required: true, },]} className="w-800" >
            <Input />
          </Form.Item>

          <Form.Item name="categories" label="文章分类" rules={[{ required: true, },]} className="w-800" >
            <Select disabled={id ? true : false}>
              <Select.Option value={idSettings.category.activities_zh + ""}>{"团队活动（中文）"}</Select.Option>
              <Select.Option value={idSettings.category.activities_en + ""}>{"团队活动（英文）"}</Select.Option>
              <Select.Option value={idSettings.category.research_zh + ""}>{"科研成果（中文）"}</Select.Option>
              <Select.Option value={idSettings.category.research_en + ""}>{"科研成果（英文）"}</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item name="brother" label="兄弟文章id" className="w-800">
            <Input placeholder="文章id在网址的末尾" />
          </Form.Item>

          <Form.Item name="content" label="摘要文" className="w-800">
            <Input.TextArea style={{ height: "110px", resize: 'none' }} />
          </Form.Item>

          <Form.Item name="figure" label="摘要图" className="w-800">
            <Editor api={api} loading={loading} type="mini" ></Editor>
          </Form.Item>

          <Form.Item name="data" label="正文" className="w-800">
            <Editor api={api} loading={loading}></Editor>
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit"> 提交</Button>
          </Form.Item>

        </Form>
      </Spin>
    </div>
  )
}


const PostEditor = ({ match }) => {
  const history = useHistory()
  const area = match.path.split("/")[1];
  const { t, i18n } = useTranslation("area")
  const { lng } = useStore()
  useEffect(() => {
    i18n.changeLanguage(lng)
  }, [])
  return (
    <div>
      <Header area={area} history={history}></Header>
      <EditorForm match={match}></EditorForm>
      <Footer></Footer>
    </div>
  )
}
export default PostEditor;
