import React, { useEffect, useState } from 'react';
import "../style/components/page-frame.css"
import LogeColor from "../asset/img/page-frame/loge-color.png"
import LogoTransparent from "../asset/img/page-frame/loge-transparent.png"
import { useTranslation } from "react-i18next";
import {  loginApi, loginRequest } from "../util/axios-api";
import { EyeInvisibleOutlined, EyeTwoTone, ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal, Input, Button } from 'antd';
import {  useDispatch } from 'react-redux'
import { useStore } from "../util"
import { PageLoading } from "../components/Loading"
const { confirm } = Modal;
const Header = ({ area, history, func, reload }) => {
  const login = loginApi()
  const { t, i18n } = useTranslation("Head")
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [modalVisible, setModalVisible] = useState(false)

  const { lng, user } = useStore()

  useEffect(() => {
    i18n.changeLanguage(lng)
  }, [])

  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)

  const handleLngChange = (lng, time = 700) => {
    setLoading(true)
    if (reload) reload(lng)
    setTimeout(() => { i18n.changeLanguage(lng); dispatch({ type: 'setLng', payload: lng }); if (func) { func() }; setLoading(false); }, time)
  }

  const changeLng = (lng) => {
    if (lng === i18n.language) return
    handleLngChange(lng, 750) //测试阶段使用
  }

  const closeModal = () => {
    setModalVisible(false)
  }

  const showModal = () => {
    setModalVisible(true)
  }

  const logout = () => {
    confirm({
      title: i18n.language === "zh" ? "你确认要退出登录吗？" : 'Do you Want to logout?',
      icon: <ExclamationCircleOutlined />,
      okText: i18n.language === "zh" ? '确认' : "confirm",
      cancelText: i18n.language === "zh" ? '取消' : "cancel",
      onOk() {
        dispatch({ type: 'logout' })
      },
      onCancel() {
      },
    });
  }

  const userLogin = () => {
    loginRequest(login, { username, password }).then(({ data: { token, user_display_name }, success }) => {
      if (success) {
        dispatch({ type: 'login', payload: token })
        dispatch({ type: 'user', payload: user_display_name })
        closeModal()
      }
    })
  }

  return (
    <div className="w- 1280">
      <PageLoading loading={loading} />
      <Modal
        centered={true}
        title={t("Login")}
        onCancel={() => closeModal()}
        visible={modalVisible}
        footer={[
          <Button key="submit" type="primary" onClick={() => userLogin()}>
            {t("submit")}
          </Button>,
          <Button key="back" onClick={() => closeModal()}>
            {t("return")}
          </Button>,
        ]}
      >
        <Input
          style={{ width: "400px", marginLeft: "36px" }}
          value={username}
          placeholder={t("input username")}
          onChange={(event) => {
            setUsername(event.target.value)
          }}
        />
        <Input.Password
          style={{ width: "400px", marginLeft: "36px", marginTop: "12px" }}
          value={password}
          placeholder={t("input password")}
          iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          onChange={(event) => {
            setPassword(event.target.value)
          }}
        />
      </Modal>

      <div className="page-frame">
        <div className="header">
          <div className="state-wrapper">
            <div className="lng">
              <p>语言 (LANGUAGE)</p>
              <p className="item" onClick={() => { changeLng("zh"); }}>中文</p>
              <p className="item" onClick={() => { changeLng("en"); }}>English</p>
            </div>
            <div className="user">
              {user ?
                <p className="item active" onClick={() => logout()}>{user}</p>
                : <p className="item" onClick={() => showModal()}>login</p>
              }
            </div>
          </div>
          <main className="w-1280">
            <div className="img-wrapper NAR" onClick={() => { history.push("/home") }}>
              <img src={LogeColor} alt="LogeColor" draggable={false} />
            </div>
            <h1 className="NAR" onClick={() => { history.push("/home") }} style={{ margin: "30px 0 0 0" }}>
              Ubiquitous Traffic Eyes
            </h1>
            <ul className="area-wrapper ">
              <li className={area === "home" ? "active" : ""} onClick={() => { history.push("/home") }}>{t("Home")}</li>
              <li className={area === "download" ? "active" : ""} onClick={() => { history.push("/download") }}>{t("Download")}</li>
              <li className={area === "applications" ? "active" : ""} onClick={() => { history.push("/applications") }}>{t("Applications")}</li>
              <li className={area === "customize" ? "active" : ""} onClick={() => { history.push("/customize") }}>{t("Customize")}</li>
              <li className={area === "about" ? "active" : ""} onClick={() => { history.push("/about") }}>{t("About")}</li>
            </ul>
          </main>
        </div>
      </div>
    </div>
  )
}

const Footer = () => {
  const { t } = useTranslation("Foot")
  return (
    <div className="w- 1280">
      <div className="page-frame">
        <div className="footer-color-wrapper">
          <div className="footer w-1280">
            <div className="img-wrapper">
              <img src={LogoTransparent} alt="LogoTransparent" draggable={false} />
            </div>
            <div className="info">
              <h1>Ubiquitous Traffic Eyes</h1>
              {/* <h2>{t("location")}</h2> */}

              <h2><a href="https://beian.miit.gov.cn">{t("record-number")}</a></h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { Footer, Header }