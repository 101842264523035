import React from 'react';
import ReactLoading from 'react-loading';
import Logo from "../asset/img/logo_UET.png"

export const PageLoading = ({ loading, children }) => {
  return (
    <>
      <div style={{ position: "fixed", zIndex: loading ? 10000 : -1, opacity: loading ? 1 : 0, height: "100vh", backgroundColor: "#eaeaea", transition: "all 0.5s" ,minWidth:"1280px"}}>
        <div className="wrapper VSAR" style={{ height: "100vh", width:"100vw" ,display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center",minWidth:"1280px", }}>
          <img src={Logo} style={{ width: "300px" }} alt="" />
          <div className="title" style={{ fontSize: "50px", fontWeight: "bolder", color: "#395f7f" }}>Ubiquitous Traffic Eyes</div>
          <ReactLoading type={'bars'} color={"#87a8c3"}></ReactLoading>
        </div>
      </div>
      <div style={{}}>{children}</div>
    </>
  )
}

