import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import "../style/pages/download.css"
import Editor from "../components/RichTextEditor"
import { Form, Tooltip, Modal, Card, Input, Skeleton, Button, Spin, message } from 'antd';
import { PlusCircleOutlined, DeleteOutlined, DownloadOutlined, PlaySquareOutlined, ExclamationCircleOutlined, EditOutlined, } from '@ant-design/icons';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { deletePost, getPost, postPost } from '../util/axios-api';
import i18next from 'i18next';
// import { useApi, axiosApi } from "../util/axiosApi"
const { confirm } = Modal;

export const DownloadUPEditor = ({ history, visible, api, id, onCancel, t, categories }) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  const loadForm = async () => {
    setLoading(true)
    const { data } = await getPost(api, id)
    const { content, videoDownload, dataDownload } = JSON.parse(data.data || {});
    form.setFieldsValue({
      title: data.title.rendered,
      "content[0]": content[0],
      "content[1]": content[1],
      "content[2]": content[2],
      "content[3]": content[3],
      "content[4]": content[4],
      figure: data.figure,
      videoDownload,
      dataDownload,
    })
    setLoading(false)
  }

  useEffect(() => {
    if (id) loadForm()
  }, [])

  const onFinish = (values) => {
    const { title, figure, videoDownload, dataDownload } = values;
    const content = [
      values["content[0]"],
      values["content[1]"],
      values["content[2]"],
      values["content[3]"],
      values["content[4]"],
    ]
    const data = JSON.stringify({
      dataDownload,
      videoDownload,
      content,
    })
    const res = {
      title,
      figure,
      data,
      categories,
      status: "publish",
    }
    postPost(api, res, id).then(({ success }) => {
      if (success) history.go(0)
      form.resetFields();
    })
  }

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 30 },
  };

  const TestAreaStyle = {
    height: "70px",
    resize: 'none'
  }

  return (
    <Modal width="900px" visible={visible} title={i18next.language === "zh" ? "下载块编辑器" : "Download block Editor"} history={history}
      okText={i18next.language === "zh" ? "确认" : "Confirm"}
      cancelText={i18next.language === "zh" ? "取消" : "Cancel"}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onFinish(values);
          })
          .catch((info) => {
          });
      }}
      onCancel={onCancel}
    >
      <Spin spinning={loading}>
        <Form form={form} labelAlign="right" {...layout} onFinish={onFinish} className="form">
          <Form.Item name="title" label={t("Title")} rules={[{ required: true }]} className="w-800" >
            <Input />
          </Form.Item>

          <Form.Item name="figure" label={t("The figure")} className="w-800">
            <Editor api={api} loading={loading} type="mini" ></Editor>
          </Form.Item>

          <Form.Item name="content[0]" label={t("Road Condition")} rules={[{ required: true }]} className="w-800">
            <Input.TextArea style={TestAreaStyle} />
          </Form.Item>

          <Form.Item name="content[1]" label={t("Video Condition")} rules={[{ required: true }]} className="w-800">
            <Input.TextArea style={TestAreaStyle} />
          </Form.Item>

          <Form.Item name="content[2]" label={t("Accuracy")} rules={[{ required: true }]} className="w-800">
            <Input.TextArea style={TestAreaStyle} />
          </Form.Item>

          <Form.Item name="content[3]" label={t("Traffic Condition")} rules={[{ required: true }]} className="w-800">
            <Input.TextArea style={TestAreaStyle} />
          </Form.Item>

          <Form.Item name="content[4]" label={t("Data Information")} rules={[{ required: true }]} className="w-800">
            <Input.TextArea style={TestAreaStyle} />
          </Form.Item>

          <Form.Item name="videoDownload" label={t("Video Link")} rules={[{ required: true }]} className="w-800">
            <Input.TextArea style={TestAreaStyle} />
          </Form.Item>

          <Form.Item name="dataDownload" label={t("Data Link")} rules={[{ required: true }]} className="w-800">
            <Input.TextArea style={TestAreaStyle} />
          </Form.Item>

        </Form>
      </Spin>
    </Modal>
  )
}

export const DownloadBox = ({ history, api, data = {}, loading, admin = false }) => {
  const { imgUrl, title, content, dataDownload, videoDownload, categories, id } = data
  const [showEditor, setShowEditor] = useState(false)
  const { t } = useTranslation("download")
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  const ShowEditor = () => {
    setShowEditor(true)
  }

  const DownLoadVideo = () => {
    Modal.success({
      content: videoDownload,
      okText: 'ok',
    });
  }

  const DownLoadData = () => {
    Modal.success({
      content: dataDownload,
      okText: 'ok',
    });
  }
  const ShowDelete = () => {
    confirm({
      title: t('Do you want to delete this download block?'),
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: i18next.language === "zh" ? '确认' : "confirm",
      cancelText: i18next.language === "zh" ? '取消' : "cancel",
      onOk() {
        deletePost(api, data.id).then(() => { history.go(0);})
      },
      onCancel() {
      },
    });
  }

  const actionIconStyle = {
    fontSize: admin ? "24px" : "32px",
    color: "#585858"
  }

  const actions = [
    (<Tooltip placement="bottom" title={t("download data")} key="download" onClick={() => DownLoadData()}>
      <DownloadOutlined style={actionIconStyle} />
    </Tooltip>),
    (<Tooltip placement="bottom" title={t("download video")} key="video" onClick={() => DownLoadVideo()}>
      <PlaySquareOutlined style={actionIconStyle} />
    </Tooltip>),
    (<Tooltip placement="bottom" title={t("edit block")} key="edit" onClick={() => ShowEditor()}>
      <EditOutlined style={actionIconStyle} />
    </Tooltip>),
    (<Tooltip placement="bottom" title={t("delete block")} key="delete" onClick={() => ShowDelete()}>
      <DeleteOutlined style={actionIconStyle} />
    </Tooltip>),
  ]
  return (
    <div>
      <DownloadUPEditor t={t} history={history} visible={showEditor} api={api} categories={categories} id={id} onCancel={() => setShowEditor(false)}></DownloadUPEditor>
      {id && <div className="download-box">
        <div className="left">
          <div className="title">{title}</div>
          <Slider {...settings}>
            {imgUrl?.map((Item, index) => {
              const bg = {
                backgroundImage: 'url(' + Item + ')'
              }
              return (
                <div key={index}>
                  <div className="picture" style={bg} >
                    <div className="mask"> </div>
                  </div>
                </div>
              )
            })}
          </Slider>
        </div>
        <div className="right">
          <Card
            actions={admin ? actions : actions.splice(0, 2)}
            style={{ borderRadius: 10 }}
          >
            <div className="content my-scroll">
              {
                loading ? <Skeleton></Skeleton> :
                  <>
                    <div className="text">
                      <h1 className="FW FS"> {t("Road Condition") + ": "}</h1>
                      <p className="FS">{content[0]}</p>
                    </div>
                    <div className="text">
                      <h1 className="FW FS">{t("Video Condition") + ": "}</h1>
                      <p className="FS">{content[1]}</p>
                    </div>
                    <div className="text">
                      <h1 className="FW FS">{t("Accuracy") + ": "}</h1>
                      <p className="FS">{content[2]}</p>
                    </div>
                    <div className="text">
                      <h1 className="FW FS">{t("Traffic Condition") + ": "}</h1>
                      <p className="FS">{content[3]}</p>
                    </div>
                    <div className="text">
                      <h1 className="FW FS">{t("Data Information") + ": "}</h1>
                      <p className="FS">{content[4]}</p>
                    </div>
                  </>
              }
            </div>
          </Card>
        </div>
      </div>}
      {
        (!id && admin) &&
        <div style={{ display: "flex", justifyContent: "center", marginBottom: "30px" }}>
          <Button type="primary" onClick={() => ShowEditor()} size={"large"} >
            <PlusCircleOutlined />{t("Add new DownLoad Box")}
          </Button>
        </div>
      }
    </div>
  )
}