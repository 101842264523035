//ApplicationEN
const lng = "en";
const info = {
  "Head": {
    lng,
    "Home": "Home",
    "Download": "Download",
    "Applications": "Research",
    "Customize": "Customize", //待修改
    "About": "About"
  },
  "Foot": {
    "location": "Nanjing Southeast University, Jiangsu Province",
    "record-number": "浙ICP备19018277号",
  },
  "about": {
    "Member-1-1":"Zhibin Li",
    "Member-1-2":"Ph.D. & Professor",
    "Member-1-3":"Southeast University",
    "Member-2-1":"Xinqiang Chen",
    "Member-2-2":" Ph.D. & lecturer",
    "Member-2-3":"Shanghai Maritime University",
    "Member-3-1":"Shunchao Wang",
    "Member-3-2":"Ph.D.",
    "Member-3-3":"Southeast University",
    "Member-4-1":"Ruyi Feng",
    "Member-4-2":"Ph.D.",
    "Member-4-3":"Southeast University",
    "Member-5-1":"Kequan Chen",
    "Member-5-2":"Ph.D.",
    "Member-5-3":"Southeast University",
    "Member-6-1":"Qifan Wu",
    "Member-6-2":"Master",
    "Member-6-3":"Southeast University",
    "Member-7-1":"Changyan Fan",
    "Member-7-2":"Master",
    "Member-7-3":"Southeast University",
    "Member-8-1":"Dongyu Tian",
    "Member-8-2":"Master",
    "Member-8-3":"Southeast University",
    "TeamsMembers":"Team members",
    "TeamActivities":"Activity information",

    lng,
    //top-title
    "top-title": "About Our Team",
    "top-A": "The UTE team is composed of traffic sensing researchers from Southeast University. The team advocates openness, cooperation and sharing. Relevant scientific research activities, research progress and scientific information will be updated constantly.",
    //NEWS
    "NEWS": "NEWS",
    "N1-T": "The title of news",
    "N1-A": "An abstract of the news and events is shown here.", //这一部分是左边滚动新闻的标题和
    "N1": "· XXXXXXXX",
    "N2": "· XXXXXXXX",
    "N3": "· XXXXXXXX",
    "N4": "· XXXXXXXX",
    "N5": "· XXXXXXXX",
    "N6": "· XXXXXXXX",
    "N7": "· XXXXXXXX",
    //Team Member
    "TM1-1": "Dr. Zhibin Li",
    "TM1-2": "Personal homepage link",
    "TM1-3": "Zhibin Li received his Ph.D. degree from the School of Transportation at the Southeast University in China. He is now a Professor at Southeast University. From 2015 to 2016, he was a postdoctoral researcher in the Smart Transportation Applications and Research Laboratory at the University of Washington, United States.From 2010 to 2012, he was a visiting student in the University of California, Berkeley.He received China National Scholarship in 2012 and 2013, and won the Best Doctoral Dissertation Award by China Intelligent ransportation Systems Association. His research interests include ITS, big data, traffic safety, and traffic flow theory.",
    "TM2-1": "Xinqiang Chen",
    "TM2-2": "Xinqiang Chen received his Ph.D. degree (2018) in traffic information engineering and controlling at the Shanghai Maritime University in China. His research interests include traffic data analysis, transportation image processing, transportation video analysis, smart ship etc. ",
    "TM3-1": "Qifan Wu",
    "TM3-2": "Qifan Wu is studying in transportation engineering at Southeast University in China.He is now a research assistant in Professor Zhibin Li’s group at Southeast University. His research interests include transportation video analysis, intelligent transport system and so on.",
    "TM4-1": "Changyan Fan",
    "TM4-2": "Changyan Fan received his bachelor degree at Central South university. He is now a research assistant in Professor Zhibin Li’s group at Southeast University. His research interests include image processing, transportation video analysis and so on.",
    "TM5-1": "Ruyi Feng",
    "TM5-2": "Ruyi Feng has been studying at the School of Transportation, Southeast University since 2017. Her research interests mainly includes intelligent transportation, image processing, vehicle tracking, and trajectory extraction.",

    //Contact Us
    "CU-1": "Do you have any questions or suggestions?",
    "CU-1-1": "Contact us here!",
    "CU-2": "Please leave an Email in order for a reply. (E-mail address will be kept secret.)",
  },
  "Applications":
  {//这个是"Applications"页面的一些内容
    "TT1":"Vehicle trajectory data contains almost all macro and micro traffic flow information, which plays an important role in revealing the mechanism behind traffic phenomena and problems. The world's most famous NGSIM trajectory data support a large number of high-quality studies by scholars around the world in traffic flow characteristics analysis, traffic operation modeling, driving behavior analysis, traffic safety discrimination and other aspects",
    "TT2":"In recent years, new algorithms such as machine vision direction have been continuously developed, which technically supports the extraction of high-precision vehicle trajectory data from aerial video. This website will continue to collate and introduce the latest research results of vehicle trajectory for discussion and reference.",

    "DATA-SET": "DATA SET",
    lng,
    "top-title": "Scientific Research",
    //top-research
    "top-res-title": "RESEARCH",
    "top-res-1": "Through diverse data, research in various directions like research on ",
    "top-res-2": "traffic flow, traffic control",//变色
    "top-res-3": ", and ",
    "top-res-4": "traffic safety ",//变色
    "top-res-5": "can be supported.",
    //top-project
    "top-pro-title": "PROJECT",
    "top-pro-1": "Through rich data acquisition methods, various projects like ",
    "top-pro-2": "ramp design ", //变色
    "top-pro-3": "and ",
    "top-pro-4": "drop-off platform design ", //变色
    "top-pro-5": "are supported.",
    //top-data
    "top-data-1": "95",
    "top-data-2": "+",
    "top-data-3": "%",
   
    //data-res
    "data-res-title": "RESEARCH",
    "data-res-1": "Traffic flow, Traffic control, and Traffic Safty can be supported.",
    "data-res-more": "MORE",
    //data-pro
    "data-pro-title": "PROJECT",
    "data-pro-1": "Ramp control design, Drop-off platform design can be supported.",
    "data-pro-more": "MORE",

    //Derived Research
    "D-R-title": "Derived Research",
    //DR1
    "DR1-T": "Trajectories and Traffic Parameters' Extraction", //变色
    "DR1-A": "Vehicle Trajectory balbala (TITLE) Published in XXX,Author: Zhibin Li, XXX,Key words: UAV, trajectory, image processing, xxx",
    //DR2
    "DR2-T": "Trajectories and Traffic Parameters' Extraction", //变色
    "DR2-A": "Vehicle Trajectory balbala (TITLE) Published in XXX Author: Zhibin Li, XXX Key words: UAV, trajectory, image processing, xxx",

    //DR2
    "DR2-T": "Trajectories and Traffic Parameters' Extraction", //变色
    "DR2-A": "Vehicle Trajectory balbala (TITLE)Published in XXXAuthor: Zhibin Li, XXX Key words: UAV, trajectory, image processing, xxx",

    //Derived Project
    "D-P-title": "Derived Project",
    //DP1
    "DP1-T": "Trajectories and Traffic Parameters' Extraction",
    "DP1-A": "Trajactory extraction from Surveillance videos based on CNN",
    //DP2
    "DP2-T": "Trajectories and Traffic Parameters' Extraction",
    "DP2-A": "Hige precision Trajactory extraction from UAV videos based on CNN",
    //DP3
    "DP3-T": "Trajectories and Traffic Parameters' Extraction",
    "DP3-A": "Trajactory extraction from Surveillance videos based on CNN",

    //Product materialization
    "P-M-title": "Product materialization",
    "P-M-1": "XXX detector",
    "P-M-2": "XXX detector",
    "P-M-3": "XXX detector",
    "P-M-4": "XXX detector",
    "P-M-5": "XXX detector",
    "P-M-6": "XXX detector",
  },
  "customize": {//这个是"Applications"页面的一些内容
    //top-title
    lng,
    "top-title": "Data extraction shared service",
    "top-A": "In order to further enrich the scenarios covered by data and enhance the research value, the website can provide services such as track data extraction, sharing and publishing under customized requirements. Video files, data introduction, extraction requirements and research value can be sent to UbiquitousTrafficEye@outlook.com, and we will reply to you soon.",
    //button
    "INS": "Download Customized Agreement",
    "CUS": "CUSTOMIZING",
    //INS
    "ins-title": "Instructions",
    "DC-T": "DATASET CUSTOMIZING",
    "DC-A": "is a personalized customized data service we provide. You need to provide the video from which the data needs to be extracted, whether it is from a drone perspective or a surveillance camera perspective. We will process the vehicle information into a dataset for feedback.",
    "IC-T": "INFORMATION CONTAINED",
    "IC-A": "in the data set includes time and space information such as vehicle position, speed and acceleration, and traffic parameter information during traveling of the vehicle. At the same time, we will also feed back the video reference of the corresponding data set marked with the vehicle number.",
    "NI-T": "NECESSARY INFORMATION",
    "NI-A-1": "is required to help us figure out your needs. The following information will be filled in the customization page.",
    "NI-A-2": "1. Why do you need to customize this data ?",
    "NI-A-3": "2. This data will be used for commercial / academic / private fields.",
    "NI-A-4": "3. Do you agree to publish your customized data set on this website ?",
    "NI-A-5": "4. The detailed information of the video is also required.",
    "NI-A-6": "5. Leaving your email address is helpful for getting feedback.",
    //Start Customision
    "SC-1": "START YOUR CUSTOMISION",
    "SC-2": "You need to download the customized agreement, read the article and fill in your requirements. Send an E-mail to Seutraffic@163.com to contact us.",
    "SC-3": "Get Agreement",
  },
  "home":
  {//这个是"Applications"页面的一些内容
    //top-title
    "Our-data": " Our data contains and applicable research",
    "Out-data-text1": " The research team used a drone with a 4K high-definition camera to perform aerial photography of road traffic flow, and extracted high-resolution vehicle trajectory data from the video. It overcomes the difficulties of UAV multi-dimensional image motion, small imaging and low-pixel vehicle detection, low-frequency jitter of vehicle tracking frames, broken track recognition and stitching, and dynamic image road coordinate transformation. The work also optimized the vehicle location, model, length and other information extraction algorithms, and established a complete video vehicle trajectory automatic extraction technology. Target detection by millimeter wave or lidar on urban roads, and multi-source data fusion, supplementation, verification, enhancement, etc. under the same coordinate system.",
    "label1-1": "Multi-dimensional Motion",
    "label1-2": "Small Target Detection",
    "label1-3": "Fracture Track Splicing",
    "label1-4": "Overcome Tracking Jitter",
    "label1-5": "Road Coordinate Transformation",
    "Out-data-text2": "The vehicle trajectory database includes parameters such as vehicle number, position coordinates, lane number, vehicle length, vehicle width, driving speed, headway time, headway distance, acceleration and deceleration, etc. The time accuracy is 0.1 second, and the position accuracy is 0.01 meter.",
    "label2-1": "Vehicle ID",
    "label2-2": "Vehicle Position",
    "label2-3": "Lane ID",
    "label2-4": "Vehicle Size",
    "label2-5": "Speed",
    "label2-6": "Time Headway",
    "label2-7": "Space Headway",
    "label2-8": "Acceleration and Deceleration",
    "label2-9": "Time Accuracy",
    "label2-10": "Position Accuracy",

    "Out-data-text3": "The team took aerial photographs of multiple road traffic flows, ranging in height from 30 meters to 250 meters. The test results show that the target number detection accuracy of the algorithm is about 95-98%, and the vehicle position accuracy is about 95-99%. The team manually corrected and verified a small number of vehicle position deviations to ensure 100% vehicle detection and tracking in the open trajectory data set.",
    "label3-1": "Height Range",
    "label3-2": "Accuracy Range",
    "label3-3": "Open Access",

    "Out-data-text4": "The team will gradually enrich the road sections covered by the trajectory data (straight, interweaving, merging, diversion, lane changes, construction sections, etc.), traffic status (free flow, congested flow, synchronized flow, state transition process, etc.), traffic scenes (maneuvering) Traffic flow, motor vehicle-non-motor vehicle mixed traffic flow, etc.) and meteorological conditions. These data can be used for research on traffic flow theory modeling, traffic flow characteristics analysis, traffic operation evaluation, driving behavior analysis, and traffic conflict discrimination.",

    "label4-1-1": "Straight",
    "label4-1-2": "Interweave",
    "label4-1-3": "confluence",
    "label4-1-4": "Diversion",
    "label4-1-5": "Lane change",
    "label4-1-6": "Construction section",


    "label4-2-1": "Free flow",
    "label4-2-2": "Congested flow",
    "label4-2-3": "Synchronous stream",
    "label4-2-4": "State transition process",

    "label4-3-1": "Motor vehicle flow",
    "label4-3-2": "Motor vehicle and non-motor vehicle mixed flow",

    "top-title-1": "E",
    "top-title-2": "xtensive Site ",//变色
    "top-title-3": "Different ",
    "top-title-4": "V",//变色
    "top-title-5": "isual",
    "top-title-6": "Diverse",
    "top-title-7": "D",//变色
    "top-title-8": "ata",
    //top-type
    "top-type-1": "UAV videos",
    "top-type-2": "Surveillance videos",
    "top-type-3": "Other videos",
    //top-data
    "top-data-1": "10",
    "top-data-2": "+",
    "top-data-3": "of Sites",
    "top-data-4": "95",
    "top-data-5": "+",
    "top-data-6": "%",
    "top-data-7": "Accuracy",
    "top-data-8": "Thousands",
    "top-data-9": "Trajectory & Data",
    //top-paragraph
    "top-paragraph-1": "Data is the basis of scientific research in traffic engineering. Vehicle trajectory data contains almost all macro and micro traffic flow information, which plays an important role in revealing the mechanism behind traffic phenomena and problems. There are position errors and low accuracy in NGSIM trajectory data, which cannot be repaired. In addition, the database coverage of road section length, time range, road environment, traffic status, vehicle composition, etc., is limited, so far, it has not been extended and updated in a large scope. China's road traffic conditions are complex, traffic laws and driving characteristics are greatly different from those of foreign countries, and there are few high-precision traffic flow data under multiple scenes, which restrict the development of scientific research. In recent years, aerial photography technology has been rapidly developed. UAV has the advantages of flexibility, rapid layout, God's perspective and so on. If permitted, it can collect traffic videos under various road environments and scenes. In recent years, new algorithms such as machine vision direction have been developed, which technically support the extraction of high-precision vehicle trajectory data from aerial video. It can support the analysis of traffic flow characteristics, traffic operation modeling, driving behavior analysis, traffic safety discrimination and other research.",
    "top-paragraph-2": "UAVs have the advantages of flexibility, rapid deployment, and God's perspective. Combining with the proposal of a series of new algorithms such as deep learning in the direction of target detection in recent years, it technically supports the extraction of high-precision vehicle trajectory data from aerial video. Since 2015, the team has carried out research on the direction of UAV aerial traffic information extraction. After years of research and practice, it has built a technology system for traffic target detection and trajectory data extraction from the UAV perspective and the roadside perspective. ",
    "top-paragraph-3": " The research team photographed and extracted vehicle trajectory data in scenes such as urban expressways and urban intersections. However, due to limitations in research fields and analysis capabilities, the value of data science could not be fully tapped, so the data was desensitized and shared publicly. And with the encouragement and support of industry peers, this website has been established to provide a platform for trajectory data download and data sharing. It is hoped that we can continuously enrich the data scenarios and value with the help of joint forces, and contribute to the in-depth analysis and study of traffic operation characteristics. Due to limitations in ability and thinking, please suggest any deficiencies.",
    //top-button
    "top-button": "Get more here",

    //Production & Application
    "P-A-title-1": "Production",
    "P-A-title-2": " & ", //变色
    "P-A-title-3": "Application",

    //Team Member
    "T-M-title": "Team Member", //蓝色
    "T-M-1": "This team is led by Professor ",
    "T-M-2": "Zhibin Li", //加粗
    "T-M-3": ", who mainly studies traffic data acquisition and information interaction based on CV. ",
    "T-M-4": "By using the acquired data, the team members also did extended research. Click below for more details. ",
    "T-M-button": "Get more details",

    //Data Download
    "D-D-title": "Data Download", //蓝色
    "D-D-1": "At ",
    "D-D-2": "extensive sites", //加粗
    "D-D-3": ", videos and traffic flow data extracted from ",
    "D-D-4": "diverse perspectives ",//加粗
    "D-D-5": "are available at this station. ",
    "D-D-6": "Click below for more details and ",
    "D-D-7": "data download. ", //加粗
    "D-D-button": "Get more details",

    //Contact Us
    "C-U-title": "Team Member", //蓝色
    "C-U-1": "If you have any questions and suggestions, or need to customize personalized data, you can contact us via message or email.",
    "C-U-button": "Get more details",
  },
  "download": {
    "top-data-4": "Accuracy",
    "top-data-5": "10",
    "top-data-6": "+",
    "top-data-7": "Sites",
    "top-data-8": "Thousands",
    "top-data-9": "Trajectory",
    "top-data-10": "Trajectory & Data",
    "top-data-11": "4",
    "top-data-12": "+",
    "top-data-13": "Devices",
    "click jump": "Click to jump",
    "top-title-1": "Data Download",
    "top-title-2": "Data available for download from this website include: video files with object identification box, raw track data and data format description. The original vehicle track data includes vehicle number, position coordinates, lane number, vehicle length, vehicle width, driving speed, time headway, space headway, plus/minus speed and other parameters, with a time accuracy of 0.1 second and a position accuracy of 0.01 m.",
    "top-title-3": "The vehicle trajectory database includes vehicle number, position coordinate, lane number, vehicle length, vehicle width, driving speed, time headway, space headway, acceleration and deceleration and other parameters, with time accuracy of 0.1 second and position accuracy of 0.1 meter.",
    "DI_text": "At present, the downloadable data contains many segments, and the coverage scenarios include urban expressway aerial photography data, urban intersection aerial photography data, urban expressway roadside detection data, and urban intersection roadside detection data, etc. The data time length includes 3min to 7min. The data directory is shown in the following table:",
    //Data Index
    "D-I-title": "Data Information ",
    "D-I-1": "UAV Data", //变色
    "D-I-2": "Surveillance Data",
    "D-I-3": "Stay tuned",
    "D-I-4": "Stay tuned",
    //Data based on UAV
    "D-UAV-title": "Data based on UAV",
    //Data based on Surveillance
    "D-Sur-title": "Data based on Surveillance"
  },"post":{
    "desc": "Ascending by time",
    "asc": "Descending by time",
    "1":"Team Activities",
    "2":"Derived Research",
    "3":"Derived Project",
  }
}
export default info;