import React, { useState, useEffect } from 'react';
import { Table, Input, InputNumber, Popconfirm, Form,  Button, Spin } from 'antd';
import "../style/components/editable-table.css"
import { getPageData, updatePageData } from '../util/axios-api';
import { useTranslation } from 'react-i18next';
import { idSettings } from '../config';
const originData = [];


export const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export const EditableTable = ({ api, admin = true }) => {
  const [t, i18n] = useTranslation("download")
  const [form] = Form.useForm();
  const [data, setData] = useState(originData);
  const [editingKey, setEditingKey] = useState('');// 正在编辑的行的key
  const [loading, setLoading] = useState(false)
  const isEditing = (record) => record.key === editingKey;

  const id = i18n.language==="zh"?idSettings.page.download_zh:idSettings.page.download_en

  const loadTable = async () => {
    setLoading(true)
    const res = await getPageData(api, { id })
    setData(res)
    setLoading(false)
  }

  const updateTable = async (data) => {
    updatePageData(api, { id, data: JSON.stringify(data) }, "更新成功")
  }

  const handleAddRow = () => {
    const newData = data.concat({ key: data.length })
    setData(newData)
    updateTable(newData)
  }
  const handleDelete = (key) => {
    const newData = data.filter((item) => item.key !== key).map((item, index) => {
      return {
        ...item,
        key: index,
      }
    })
    setData(newData)
    updateTable(newData)
    setEditingKey('');
  }

  useEffect(() => {
    loadTable()
  }, [id])


  const edit = (record) => {
    form.setFieldsValue({
      Site: '',
      Range: '',
      Duration: '',
      Track: '',
      Lanes: '',
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        setEditingKey('');
        updateTable(newData);
      } else {
        newData.push(row);
        setData(newData);
        updateTable(newData);
        setEditingKey('');
      }

    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const columns = [
    {
      title: t('Site'),
      dataIndex: 'Site',
      // width: '15%',
      editable: true,
      align: 'center',
    },
    {
      title: t('Range'),
      dataIndex: 'Range',
      // width: '10%',
      editable: true,
      align: 'center',
    },
    {
      title: t('Duration'),
      dataIndex: 'Duration',
      // width: '10%',
      editable: true,
      align: 'center',
    },
    {
      title: t("Number of Track"),
      dataIndex: 'Track',
      // width: '15%',
      editable: true,
      align: 'center',
    },
    {
      title: t("Number of Lanes"),
      dataIndex: 'Lanes',
      // width: '15%',
      editable: true,
      align: 'center',
    },
    {
      title: t('operation'),
      dataIndex: 'operation',
      width:"12%",
      align: 'center',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <div style={{ display: 'flex', flexDirection: "column" }}>
            <Button style={{ marginBottom: "10px" }} onClick={() => save(record.key)}>{t("Save")}</Button>
            <Button style={{ marginBottom: "10px" }} onClick={cancel}>{t("Cancel")}</Button>
            <Popconfirm style={{ marginBottom: "10px" }} title={t("Sure to delete?")} onConfirm={() => handleDelete(record.key)}>
              <Button>{t("Delete")}</Button>
            </Popconfirm>
          </div>
        ) : (
          <div style={{ display: 'flex', flexDirection: "column" }}>
            <Button disabled={editingKey !== ''} onClick={() => edit(record)}>{t("Edit")}</Button>
          </div>
        );
      },
    },
  ];

  // 设置表头
  const mergedColumns = (admin ? columns : columns.slice(0, -1)).map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Spin spinning={loading}>
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{ hideOnSinglePage: true, }}
        />
        {admin && <Button type="primary" style={{ marginTop: "10px" }} onClick={() => handleAddRow()}>{t("Add Row")}</Button>}
      </Form>
    </Spin>
  );
};