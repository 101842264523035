import React from 'react';

import "../style/components/carousel-map.css"
import { Skeleton } from 'antd';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SectionTitle } from "./index"

/**
 * @description 用于实现一个走马灯卡片的呈现试图组件
 * @param {*} loading 是否正在加载 
 * @param {*} history 路由
 * @param {*} item  用于渲染的数据模型
 * @param {*} type  呈现的不同样式  "mini" 表示小号的 
 * @param {*} more  表示是否为更多 more中传入需要加载的数据
 * @returns 
 */
const CarouselCard = ({ loading, history, item, type }) => {
  const { imgUrl, title, content, url, id } = item
  const mini = type === "mini"
  const inlineStyle = {
    width: "320px",
    height: mini ? "300px" : "500px",
    backgroundImage: loading ? undefined : 'url(' + imgUrl + ')'
  }
  return (
    <div className="carousel-card" style={inlineStyle} onClick={() => { history.push(url) }} key={id}>
      <div className="mask" >
        <div className="content" >
          {loading ? <Skeleton active /> :
            <div >
              <h1 className="title" >{title}</h1>
              <h1 className="des">{content}</h1>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

const defaultCardList = [
  {
    id: "1",
    imgUrl: "../asset/img/example/example-bg.png",
    title: "Loading...",
    content: "loading..."
  }, {
    id: "2",
    imgUrl: "../asset/img/example/example-bg.png",
    title: "Loading...",
    content: "loading..."
  }, {
    id: "3",
    imgUrl: "../asset/img/example/example-bg.png",
    title: "Loading...",
    content: "loading..."
  }
]


/**
 * @description 
 * @param {*} param0 
 * @returns 
 */

const CarouselMap = ({ loading, history, title, cardList = defaultCardList, logo, type }) => {
  return (
    <div className="carousel">
      {logo && <SectionTitle logo={logo} title={title}></SectionTitle>}
      <div className="slick-wrapper">
        <SlickCarousel cardList={cardList} loading={loading} history={history} type={type}></SlickCarousel>
      </div>
    </div>
  )
}

const SlickCarousel = ({ cardList, loading, history, type }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
  };
  return (
    <Slider {...(settings)}>
      {cardList.map((item) => {
        return <div key={item.id}><CarouselCard item={item} loading={loading} history={history} type={type} key={item.id} /></div>
      })}
    </Slider>
  )
}

export { CarouselMap, CarouselCard, SlickCarousel }