import { message } from 'antd'
import { useSelector, useDispatch } from 'react-redux'

export const useStore = () => {
  return useSelector((state) => state)
}


const categories = ["null", "Team Activities", "Derived Research", "Derived Project", "Data based on UAV", "Data based on Surveillance"]

const URLCategories = ["null", "Team_Activities", "Derived_Research", "Derived_Project", "Data_based_on_UAV", "Data_based_on_Surveillance"]



export const getCategoryID = (name) => {
  for (let index = 0; index < categories.length; index++) {
    const element = categories[index];
    if (element === name) return index
  }
  return 0
}

export const getCategory = (id) => {
  return categories[id]
}

export const getURLCategoryID = (name) => {
  for (let index = 0; index < URLCategories.length; index++) {
    const element = URLCategories[index];
    if (element === name) return index
  }
  return 0
}

export const getURLCategory = (id) => {
  return URLCategories[id]
}
