import { message } from 'antd'
import { createStore } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const initialState = {
  isLogin: false,
  apiToken: undefined,
  user: undefined,
  hasMessage:undefined,
  lng:"en"
}

const actions = {
  login (state, payload) {
    if(state.lng==="zh")message.success('登录成功！')
    else message.success('Login successfully!')
    state.apiToken = payload
    state.isLogin = true
    return state
  },
  logout (state) {
    if(state.lng==="zh")message.success('退出登录成功！')
    else message.success('Logout successfully!')
    
    state.isLogin = false
    state.apiToken = undefined
    state.user = undefined
    state.hasMessage = undefined
    return state
  },
  expire (state) {
    if(state.lng==="zh")message.warning('登录信息过期!')
    else message.warning('Login information expired!')
    state.isLogin = false
    state.apiToken = undefined
    state.user = undefined
    state.hasMessage = undefined
    return state
  },
  user (state, payload) {
    state.user = payload
    return state
  },
  setMessageStatus (state,payload) {
    state.hasMessage = payload
    return state
  },
  setLng(state,payload){
    state.lng = payload
    return state
  }
}

const reducer = (state = initialState, action) => {
  if (typeof actions[action.type] === 'function') {
    const newState = JSON.parse(JSON.stringify(state))
    return actions[action.type](newState, action.payload)
  }
  return initialState
}

const persistedReducer = persistReducer({
  key: 'tungsten-redux',
  storage
}, reducer)

const store = createStore(persistedReducer)
const persistor = persistStore(store)


export { store, persistor}

