const lng = "zh";
const info = {
  "Head": {
    lng,
    "Login":"登录",
    "submit":"确认",
    "return":"取消",
    "Home": "首页",
    "input username":"输入用户名",
    "input password":"输入密码",
    "Download": "数据下载",
    "Applications": "科学研究",
    "Customize": "数据提取",
    "About": "关于我们"
  },
  "Foot": {
    "location": "江苏省南京市 东南大学",
    "record-number": "浙ICP备19018277号",
  },
  "about": {
    "Member-1-1":"李志斌",
    "Member-1-2":"博士、教授",
    "Member-1-3":"东南大学",
    "Member-2-1":"陈信强",
    "Member-2-2":" 博士、讲师",
    "Member-2-3":"上海海事大学",
    "Member-3-1":"王顺超",
    "Member-3-2":"博士",
    "Member-3-3":"东南大学",
    "Member-4-1":"冯汝怡",
    "Member-4-2":"博士",
    "Member-4-3":"东南大学",
    "Member-5-1":"陈科全",
    "Member-5-2":"博士",
    "Member-5-3":"东南大学",
    "Member-6-1":"吴启范",
    "Member-6-2":"硕士",
    "Member-6-3":"东南大学",
    "Member-7-1":"范昌彦",
    "Member-7-2":"硕士",
    "Member-7-3":"东南大学",
    "Member-8-1":"田栋宇",
    "Member-8-2":"硕士",
    "Member-8-3":"东南大学",
    "TeamsMembers":"Team members",
    "TeamActivities":"Activity information",
    "TeamsMembers":"团队成员",
    "TeamActivities": "动态资讯",
    "top-title": "团队动态",
    "top-A": "UTE团队由来自东南大学等交通感知方向研究人员组成，团队主张开放、合作、共享，相关科研活动、研究进展与科技资讯等将会不断更新，期待您的关注！",
    //NEWS
    "NEWS": "新闻",
    "N1-T": "标题",
    "N1-A": "新闻内容",
    //这一部分是左边滚动新闻的标题和
    "N1": "· XXXXXXXX",
    "N2": "· XXXXXXXX",
    "N3": "· XXXXXXXX",
    "N4": "· XXXXXXXX",
    "N5": "· XXXXXXXX",
    "N6": "· XXXXXXXX",
    "N7": "· XXXXXXXX",
    //团队成员
    "TM1-1": "李志斌 教授",
    "TM1-2": "个人主页链接",
    "TM1-3": "东南大学交通学院教授、博士研究生导师。毕业于东南大学交通运输规划与管理专业，获博士学位。在美国加州大学伯克利分校、美国华盛顿大学智能交通应用与研究实验室、香港理工大学等进行学习工作。研究方向包括智能交通系统、交通大数据、交通安全和交通流理论。",
    "TM2-1": "陈信强",
    "TM2-2": "2018年毕业于上海海事大学，获得交通信息工程与控制博士学位。2015年9月至2016年9月在美国华盛顿大学智能交通应用与研究实验室访问研究。他是13多篇技术论文的作者和合著者。他的研究方向包括交通数据分析、交通图像处理、交通视频分析、智能船舶等。",
    "TM3-1": "吴启范",
    "TM3-2": "2016年至今就读于东南大学吴健雄学院。他现在是东南大学李志斌教授团队的研究助理。他的研究方向包括交通视频分析、智能交通系统等。",
    "TM4-1": "范昌彦",
    "TM4-2": "2019年毕业于中南大学，获学士学位，现为东南大学李志斌教授的硕士研究生，他的研究方向包括图像处理，交通视频分析等。",
    "TM5-1": "冯汝怡",
    "TM5-2": "2017年至今就读于东南大学交通学院 。研究方向主要包括智能交通、图像处理、车辆跟踪和轨迹提取。",
    //Contact Us
    "CU-1": "如果您有任何问题和建议",
    "CU-1-1": "欢迎在这儿联系我们!",
    "CU-2": "请留下您的联系方式以便我们回复（联系方式我们均会保密）",
  },
  "Applications": {
    "TT1":"车辆轨迹数据中包含几乎全部宏微观交通流信息，对于揭示交通现象和问题背后机理具有重要支撑作用。世界上最知名的NGSIM轨迹数据等支撑了世界范围学者在交通流特性解析、交通运行建模、驾驶行为分析、交通安全判别等方面的大量高质量研究。",
    "TT2":"近年来，机器视觉方向等新算法不断发展，技术上支撑了航拍视频中提取高精度车辆行驶轨迹数据。本网站将持续整理并介绍车辆轨迹方面最新研究成果供研讨参考。",
    "DATA-SET": "数据集",
    lng,
    //top-title
    "top-title": "科学研究",
    //top-research
    "top-res-title": "研究内容",
    "top-res-1": "基于所获得的不同场景条件下的交通流轨迹数据，可以支持 ",
    "top-res-2": "交通流，交通控制",//变色
    "top-res-3": ", 和 ",
    "top-res-4": " 交通安全",//变色
    "top-res-5": "等方向的研究.",
    //top-project
    "top-pro-title": "项目内容",
    "top-pro-1": "通过丰富的数据采集方法，可以为各种项目所需的 ",
    "top-pro-2": "车道设计 ", //变色
    "top-pro-3": "和 ",
    "top-pro-4": "落客平台 ", //变色
    "top-pro-5": "提供支持.",
    //top-data
    "top-data-1": "95",
    "top-data-2": "+",
    "top-data-3": "%",
    
    //data-res
    "data-res-title": "研究内容",
    "data-res-1": "可以支持交通流、交通控制和交通安全方向的研究。",
    "data-res-more": "更多",
    //data-pro
    "data-pro-title": "项目内容",
    "data-pro-1": "可以支持车道设计，落客平台设计等项目进展.",
    "data-pro-more": "更多",

    //Derived Research
    "D-R-title": "科学研究",
    //DR1
    "DR1-T": "轨迹与交通参数提取", //变色
    "DR1-A": "轨迹数据提取 (标题)Published in XXXAuthor: Zhibin Li, XXXKey words: UAV, trajectory, image processing, xxx",

    //DR2
    "DR2-T": "轨迹与交通参数提取",//变色
    "DR2-A": "Vehicle Trajectory balbala (TITLE) Published in XXXAuthor: Zhibin Li, XXX Key words: UAV, trajectory, image processing, xxx",

    //DR3
    "DR3-T": "轨迹与交通参数提取",//变色
    "DR3-A": "Vehicle Trajectory balbala (TITLE) Published in XXXAuthor: Zhibin Li, XXXKey words: UAV, trajectory, image processing, xxx",

    //Derived Project
    "D-P-title": "科技项目",
    //DP1
    "DP1-T": "轨迹与交通参数提取",
    "DP1-A": "基于CNN的监控视频平移提取",
    //DP2
    "DP2-T": "轨迹与交通参数提取",
    "DP2-A": "基于CNN的无人机视频高精度轨迹提取",
    //DP3
    "DP3-T": "轨迹与交通参数提取",
    "DP3-A": "基于CNN的监控视频平移提取",

    //Product materialization
    "P-M-title": "产品衍生",
    "P-M-1": "XXX detector",
    "P-M-2": "XXX detector",
    "P-M-3": "XXX detector",
    "P-M-4": "XXX detector",
    "P-M-5": "XXX detector",
    "P-M-6": "XXX detector",
  },
  "customize": {//这个是"Applications"页面的一些内容
    //top-title
    lng,
    "top-title": "数据提取共享服务",
    "top-A": "为了进一步丰富数据涵盖场景及提升研究价值，网站可提供定制化需求下轨迹数据提取与共享发布等服务，可将视频文件、数据介绍、提取要求、研究价值等发送至UbiquitousTrafficEye@outlook.com，我们将很快回复您。",
    //button
    "INS": "下载定制协议",
    "CUS": "定制服务",
    //INS
    "ins-title": "内容说明",
    "DC-T": "数据集定制",
    "DC-A": "数据集定制是我们提供的个性化数据服务。根据您提供的需求，从视频中提取您所需的数据。无论是从无人机角度还是从监控摄像头角度，我们都将会为您把研究所需的车辆信息处理成完整的数据集。",
    "IC-T": "数据内容",
    "IC-A": "数据集中包括车辆位置、速度和加速度等时间和空间信息，以及车辆行驶过程中的交通参数信息。同时，我们还将反馈相应数据集的视频参考，并标注车辆编号。",
    "NI-T": "所需信息",
    "NI-A-1": "为了更好的帮助我们了解你的需求。请将以下信息填写在定制页面中（我们会严格保密）。",
    "NI-A-2": "1. 定制数据的需求。",
    "NI-A-3": "2. 这些数据将用于商业 / 学术还是私人领域。",
    "NI-A-4": "3. 您同意在本网站上发布您的自定义数据集吗 ?",
    "NI-A-5": "4. 另外所需的视频的其他详细信息.",
    "NI-A-6": "5. 留下你的电子邮件地址有助于获得回复。",
    //Start Customision
    "SC-1": "开始您的定制",
    "SC-2": "定制数据需要下载定制协议，并补全您的需求后发送邮件至UbiquitousTrafficEye@outlook.com联系我们。",
    "SC-3": "获取协议",
  },
  "download": {//这个是"Applications"页面的一些内容
    //top-title
    "top-data-4": "精度",
    "top-data-5": "10",
    "top-data-6": "+",
    "top-data-7": "场景",
    "top-data-8": "千",
    "top-data-9": "量级",
    "top-data-10": "轨迹数据",
    "top-data-11": "4",
    "top-data-12": "+",
    "top-data-13": "设备",
    "click jump": "点击跳转",
    "top-title-1": "数据下载",
    "top-title-2": "本网站提供下载的数据包括：带目标识别框的视频文件、原始轨迹数据及数据格式说明。原始车辆轨迹数据包括车辆编号、位置坐标、车道编号、车辆长度、车辆宽度、行驶速度、车头时距、车头间距、加减速度等参数，时间精度为0.1秒，位置精度为0.01米。",
    "top-title-3": "目前可下载数据覆盖场景包括城市快速路航拍数据、城市交叉口航拍数据、城市快速路路侧检测数据、城市交叉口路侧检测数据等。",
    "DI_text": "目前可下载数据共包含几段，覆盖场景包括城市快速路航拍数据、城市交叉口航拍数据、城市快速路路侧检测数据、城市交叉口路侧检测数据等。数据时间长度包括3min至7min。数据目录如下表所示：",
    //Data Index
    "D-I-title": "数据信息",
    "D-I-1": "无人机数据", //变色
    "D-I-2": "监控数据",
    "D-I-3": "敬请期待",
    "D-I-4": "敬请期待",
    "Site": "场景",
    "Range": "范围",
    "Duration": "时长",
    "Number of Track": "样本数",
    "Number of Lanes": "车道数",
    "operation": "操作",
    "Edit": "操作",
    "Save": "保存",
    "Delete": "删除",
    "Cancel": "取消",
    "Sure to delete?": "确认删除",
    "Add Row": "增加行",
    "Add new DownLoad Box": "增加新的下载块",
    //Data based on UAV
    "D-UAV-title": "基于无人机的航拍数据",

    "D-Sur-title": "监控视频数据集",

    "Road Condition": "道路情况",
    "Video Condition": "视频拍摄状况",
    "Accuracy": "精度",
    "Traffic Condition": "交通状况",
    "Data Information": "数据信息",
    "download data": "下载数据",
    "download video": "下载视频",
    "edit block": "编辑",
    "delete block": "删除",
    "Video Link": "视频链接",
    "Data Link": "数据链接",
    'Do you want to delete this download block?': "确认删除该下载块？",
    "Title": "标题",
    "Category": "分类",
    "The figure": "摘要图",
    "Data based on UAV": "基于无人机的航拍数据",
    "Data based on Surveillance": "监控视频数据集",
  },
  "home": {
    "Our-data": " 数据特色",
    "Out-data-text1": "	研究团队使用携带4K高清摄像头无人机对道路交通流运行进行航拍，从视频中提取出高解析度车辆行驶轨迹数据。克服了无人机多维度画面运动、小成像低像素车辆检测、车辆跟踪帧低频抖动、断裂轨迹识别拼接、动态图像道路坐标变换等难点，优化了车辆位置、车型、长度等信息提取算法，建立了完整的视频车辆轨迹自动提取技术。在城市道路上通过毫米波/激光雷达对于目标检测，并在同一坐标体系下进行多源数据融合、补充、校验、增强等。",
    "label1-1": "多维度画面运动",
    "label1-2": "小成像低像素",
    "label1-3": "断裂轨迹拼接",
    "label1-4": "克服追踪抖动",
    "label1-5": "动态道路坐标变换",
    "Out-data-text2": "车辆轨迹数据库包括车辆编号、位置坐标、车道编号、车辆长度、车辆宽度、行驶速度、车头时距、车头间距、加减速度等参数，时间精度为0.1秒，位置精度为0.01米。",
    "label2-1": "车辆编号",
    "label2-2": "位置坐标",
    "label2-3": "车道编号",
    "label2-4": "车辆长宽",
    "label2-5": "行驶速度",
    "label2-6": "车头时距",
    "label2-7": "车头间距",
    "label2-8": "加减速度",
    "label2-9": "时间精度",
    "label2-10": "位置精度",
    "Out-data-text3": "	团队对多条道路交通流进行航拍，高度范围为30米至250米。测试表明算法的目标数检测精度为95%-98%左右，车辆位置精度为95%-99%。团队对少量车辆位置偏差进行了人工校正和验证，确保开放轨迹数据集实现100%车辆检测和跟踪。",
    "label3-1": "高度范围",
    "label3-2": "精度范围",
    "label3-3": "开放轨迹",
    "Out-data-text4": "	团队将逐渐丰富轨迹数据涵盖的道路路段（平直、交织、合流、分流、车道变化、施工路段等）、交通状态（自由流、拥堵流、同步流、状态转变过程等）、交通场景（机动车流、机动车-非机动车混合交通流等）及气象条件。这些数据可以用于交通流理论建模、交通流特性分析、交通运行评价、驾驶行为分析、交通冲突判别等研究。",
    "label4-1-1": "平直",
    "label4-1-2": "交织",
    "label4-1-3": "合流",
    "label4-1-4": "分流",
    "label4-1-5": "车道变化",
    "label4-1-6": "施工路段",
    "label4-2-1": "自由流",
    "label4-2-2": "拥堵流",
    "label4-2-3": "同步流",
    "label4-2-4": "状态转变过程",
    "label4-3-1": "机动车流",
    "label4-3-2": "机动车-非机动车混合流",
    //这个是"Applications"页面的一些内容
    //top-title
    "top-title-1": "E",
    "top-title-2": "xtensive Site ",//变色
    "top-title-3": "Different ",
    "top-title-4": "V",//变色
    "top-title-5": "isual",
    "top-title-6": "Diverse",
    "top-title-7": "D",//变色
    "top-title-8": "ata",
    //top-type
    "top-type-1": "航拍视频",
    "top-type-2": "监控录像",
    "top-type-3": "其它类型",
    //top-data
    "top-data-1": "10",
    "top-data-2": "+",
    "top-data-3": "场景",
    "top-data-4": "95",
    "top-data-5": "+",
    "top-data-6": "%",
    "top-data-7": "准确度",
    "top-data-8": "",
    "top-data-9": "轨迹数据",//千 字放大
    "top-data-10": "轨迹数据",
    //top-paragraph
    "top-paragraph-1": "数据是交通工程学科科学研究的基础。车辆轨迹数据中包含几乎全部宏微观交通流信息，对于揭示交通现象和问题背后机理具有重要支撑作用。NGSIM轨迹数据轨迹中存在位置错误、精度不高等无法修复问题；此外数据库覆盖路段长度、时间范围、道路环境、交通状态、车辆组成等均有限，至今未能进行大范围扩展与更新。我国道路交通情况复杂、交通规律与驾驶特性与国外存在较大差异，多场景下高精度交通流数据较少，制约了科学研究开展。近年来，航拍技术得到了飞速发展，无人机具有机动灵活、快速布设、上帝视角等优势，在允许下可采集各种道路环境及场景下交通视频；近年来机器视觉方向等新算法不断发展，技术上支撑了航拍视频中提取高精度车辆行驶轨迹数据。可支撑交通流特性解析、交通运行建模、驾驶行为分析、交通安全判别等方面研究。",
    "top-paragraph-2": "近年来，无人机航拍技术得到了飞速发展，无人机具有机动灵活、快速布设、上帝视角等优势。结合近年来目标检测方向深度学习等一系列新算法的提出，技术上支撑了从航拍视频中提取高精度车辆行驶轨迹数据。团队2015年起开展无人机航拍交通信息提取方向研究，经过多年研究与实践，构建了无人机视角与路侧视角下交通目标检测与轨迹数据提取技术体系。",
    "top-paragraph-3": "研究团队拍摄并提取了城市快速路、城市路口等场景下车辆轨迹数据，但由于研究领域、分析能力等局限，无法充分挖掘数据科学价值，导致数据资源闲置，便将数据进行脱敏后共享公开，并在业内同行鼓励支持下建立本网站，提供轨迹数据下载、数据分享方面的平台，希望能够借助合力不断丰富数据场景与价值，为交通工作者们深入分析研究交通运行特征和改善解决交通问题做出贡献。由于能力、思路方面局限，不足之处请多多建议。",
    //top-button
    "top-button": "了解更多",

    //Production & Application
    "P-A-title-1": "项目",
    "P-A-title-2": " & ",//变色
    "P-A-title-3": "应用",

    //Team Member
    "T-M-title": "团队成员", //蓝色
    "T-M-1": "团队由",
    "T-M-2": "李志斌教授", //加粗
    "T-M-3": "领导, 主要研究方向为xxx ",
    "T-M-4": "更多基于轨迹数据的扩展研究、项目可点击下方了解更多详细信息。",
    "T-M-button": "了解更多",

    //Data Download
    "D-D-title": "数据下载", //蓝色
    "D-D-1": "数据包含",
    "D-D-2": "广泛的地点", //加粗
    "D-D-3": "的地点",
    "D-D-4": "多角度视频",//加粗
    "D-D-5": "所提取的交通流数据",
    "D-D-6": "单机“了解更多”获取更多信息下载数据",
    "D-D-7": "。",
    "D-D-button": "了解更多",

    //Contact Us
    "C-U-title": "联系我们", //蓝色
    "C-U-1": "如果您有任何问题和建议，或需要定制个性化数据，可通过留言或邮箱联系我们。",
    "C-U-button": "了解更多"
  },
  "post": {
    "Team Activities": "团队活动",
    "Derived Research": "科学研究",
    "Derived Project": "科技项目",
    "desc": "按时间升序",
    "asc": "按时间降序",
    "Edit": "编辑",
    "Delete": "删除",
    "Do you want to delete this post?":"你确定要删除这篇文章吗？",
    "Confirm":"确认",
    "Cancel":"取消",
    "1":"团队活动",
    "2":"科学研究",
    "3":"科技项目",
    "click to get more":"点击查看更多",
  }
}
export default info