import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Header, Footer } from '../components/PageFrame'
import "../style/pages/download.css"
import { useHistory } from 'react-router-dom'
import { useStore } from "../util"

import Logo1 from '../asset/img/applications/logo1.jpg'
import Logo2 from '../asset/img/applications/logo2.jpg'
import Logo3 from '../asset/img/applications/logo3.jpg'
import Logo4 from '../asset/img/applications/logo4.jpg'

import LogoTop from "../asset/img/download/logo-top.png"
import LogoIndex from "../asset/img/download/logo-index.png"
import LogoUAV from "../asset/img/download/logo-UAL.png"
import { useApi, getDownloadList } from "../util/axios-api"
import { SectionTitle } from "../components"
import { DownloadBox } from "../components/DownLoadBox"
import { EditableTable } from "../components/EditableTable"
import { BackTop } from 'antd'
import { idSettings } from '../config';

const DataCard = ({ img, title, content }) => {
  return (
    <div className="data-card NAR" style={{ backgroundImage: img, backgroundPosition: "center" }}>
      <img src={img} alt="" className="logo" draggable={false} />
      <div className="content">{content}</div>
      <div className="title" style={{ fontSize: "24px" }}>{title}</div>
    </div>
  )
}

const DownLoad = ({ match }) => {
  const history = useHistory()
  const area = match.path.split("/")[1];
  const { t, i18n } = useTranslation("download")
  const { lng, isLogin } = useStore()
  const [loading, setLoading] = useState(false)
  const [List, setList] = useState()
  const api = useApi()
  const ZH = i18n.language === "zh"

  const loadList = async (lng) => {
    setLoading(true)
    const categories = lng === "zh" ? idSettings.category.download_zh : idSettings.category.download_en
    const list = await getDownloadList(api, { categories: categories, per_page: 10 })
    list.push({ categories })
    setList(list)
    setLoading(false)
  }

  useEffect(() => {
    loadList(i18n.language)
    i18n.changeLanguage(lng)
  }, [])

  return (
    <div className="mw-1200">
      <Header area={area} history={history} reload={loadList}></Header>
      <BackTop />
      <div className="download-page">
        <header className="top-bg">
          <div className="w-1280-r top-wrapper">
            <div className="title-wrapper">
              <div className="FS-48 COL-W FW-B MG-0">{t("top-title-1")}</div>
              <img src={LogoTop} alt="LogoTop" draggable={false} style={{ margin: "0 0 0 25px", height: "48px" }} />
            </div>
            <div className="des mw-950" style={{ width: "950px", marginTop: "25px" }}>
              <div className="td-2 FS-18">{t("top-title-2")}</div>
            </div>
          </div>
        </header>


        <div className="dl-body">
          <SectionTitle logo={LogoIndex} title={t("D-I-title")}></SectionTitle>
          <div className="ag-middle w-1280">
            <ul className="data-list">
              <DataCard img={Logo1} content={
                <>
                  <div style={{ display: "flex", alignItems: "start", color: "#476a9f", position: "relative", zIndex: "100", fontWeight: "bolder" }}>
                    <div style={{ fontSize: "80px" }}>{95}</div>
                    <div>
                      <div style={{ fontSize: "48px", lineHeight: "58px" }}>+</div>
                      <div style={{ fontSize: "48px", lineHeight: "48px" }}>%</div>
                    </div>
                  </div>
                </>
              } title={t("top-data-4")} />
              <DataCard img={Logo2} content={
                <>
                  <div style={{ display: "flex", alignItems: "start", color: "#476a9f", position: "relative", zIndex: "100", fontWeight: "bolder" }}>
                    <div style={{ fontSize: "80px" }}>{10}</div>
                    <div style={{ fontSize: "48px" }}>+</div>
                  </div>
                </>
              } title={t("top-data-7")} />
              <DataCard img={Logo3} content={
                <>
                  {i18n.language === "en" ? <div style={{ display: "flex", alignItems: "baseline", color: "#476a9f", position: "relative", zIndex: "100", fontWeight: "bolder" }}>
                    <span style={{ fontSize: "80px", display: "flex" }}>{"T"}</span><span style={{ fontSize: "30px" }}>{"housands"}</span>
                  </div> : <div style={{ display: "flex", alignItems: "baseline", color: "#476a9f", position: "relative", zIndex: "100", fontWeight: "bolder" }}>
                    <span style={{ fontSize: "30px" }}>{"上"}</span><span style={{ fontSize: "80px" }}>{"千"}</span>
                  </div>
                  }
                </>
              } title={t("top-data-9")} />
              <DataCard img={Logo4} content={
                <>
                  <div style={{ display: "flex", alignItems: "start", color: "#476a9f", position: "relative", zIndex: "100", fontWeight: "bolder" }}>
                    <div style={{ fontSize: "80px" }}>{4}</div>
                    <div style={{ fontSize: "48px" }}>+</div>
                  </div>
                </>
              } title={t("top-data-13")} />
            </ul>
          </div>
          <div className=" w-1280" style={{ position: "relative" }}>
            <div className="index-wrapper w-1280">
              <div className="info-wrapper" >
                <div className="info-text td-2" style={{ marginBottom: "30px" }}>
                  {t("DI_text")}
                </div>
                <div className="table">
                  <EditableTable loading={loading} api={api} admin={isLogin} ></EditableTable>
                </div>
              </div>


            </div>


            <SectionTitle logo={LogoUAV} title={t("D-UAV-title")} ></SectionTitle>


            <div className="w-1280" style={{ marginBottom: "30px" }}>
              {
                List?.map((Item, index) => {
                  return <DownloadBox history={history} api={api} data={Item} admin={isLogin} loading={loading} key={index}></DownloadBox>
                })
              }
            </div>

          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}
export default DownLoad;
