import React from 'react';
import { useDispatch } from 'react-redux';
import { Route,  useHistory } from 'react-router-dom';
import { useStore } from '../util';
import { useApi } from '../util/axios-api';
function NRoute(props) {
  const api = useApi()
  const dispatch = useDispatch()
  const history = useHistory()
  const { isLogin } = useStore()
  api.get("posts").catch(() => {
    if (isLogin) {
      dispatch({ type: 'expire' }); history.replace("home")
    }
  })
  return <Route{...props} />
}
export default NRoute;