import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom'
import { Header, Footer } from '../components/PageFrame'
import TeamsLogo from '../asset/img/about/teams.png'
import MessageBoard from '../components/MessageBoard'
import "../style/pages/about-page.css"
import { CarouselMap } from "../components/CarouselMap"
import { useStore } from "../util"
import { SectionTitle } from "../components"
import { useApi, getAbstractList } from '../util/axios-api';
import { idSettings, img } from '../config';

export const MemberCard = ({ name, des1, des2 }) => {
  return (
    <div className="member-card">
      <header>
      </header>
      <main >
        <div className="FW FS-24 COL-W" style={{ color: "#6d97cb" }}>{name}</div>
        <div className="FS-18 FW" style={{ color: "#585858", textAlign: "center" }}>{des1}</div>
        <div className="FS-18 FW" style={{ color: "#585858", textAlign: "center" }}>{des2}</div>
      </main>
    </div>
  )
}


const AboutPage = ({ match }) => {
  const area = match.path.split("/")[1];
  const history = useHistory();
  const { t, i18n } = useTranslation("about");
  const { lng } = useStore()
  const api = useApi()
  const [carouselList, setCarouselList] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const ZH = i18n.language === "zh"
  const loadList = async (lng) => {
    setLoading(true)
    const carousel = await getAbstractList(api, { per_page: 6, categories: lng === "zh" ? idSettings.category.activities_zh : idSettings.category.activities_en });
    carousel.push({
      id: "0",
      title: "get more...",
      content: "know more about it...",
      url: "/post/list/activities_" + i18n.language,
      imgUrl: "http://www.seutraffic.com:9090/wp-content/uploads/2021/05/moro.jpg",
    })
    setCarouselList(carousel)
    setLoading(false)
  }

  useEffect(() => {
    loadList(i18n.language)
    i18n.changeLanguage(lng)
  }, [])

  return (
    <div className="mw-1200">
      <Header area={area} history={history} reload={loadList} />
      <div className="about-page">

        <div className="top-bg">
          <div className="w-1280-r top-wrapper">
            <div className="title-wrapper">
              <div className="FS-48 COL-W FW" style={{ margin: "0" }}>{t("top-title")}</div>
              <img src={TeamsLogo} alt="" style={{ marginLeft: "15px", height: "48px" }} />
            </div>
            <div className="td-2 FS-18 COL-W mw-950" style={{ width: "950px" }}>{t("top-A")}</div>
          </div>
        </div>

        <div className="body">
          <div className="team-act">
            <CarouselMap title={t("TeamActivities")} logo={TeamsLogo} loading={loading} cardList={carouselList} history={history} type="mini" />
          </div>
          <SectionTitle title={t("TeamsMembers")} logo={TeamsLogo}> </SectionTitle>
          <div className="w-1280" style={{display:'flex',justifyContent:"center"}}>
            <div className="member-list">
              {
                [1, 2, 3, 4, 5, 6, 7, 8].map(
                  (index) => {
                    return <MemberCard name={t("Member-" + index + "-1")} des1={t("Member-" + index + "-2")} des2={t("Member-" + index + "-3")} key={index} />
                  }
                )
              }
            </div>
          </div>

        </div>
      </div>
      <MessageBoard area={"about"} />
      <Footer />
    </div>
  )
}

export default AboutPage
