

export const idSettings = {
  /**创建6个  category*/
  category: {
    activities_zh: 1,
    activities_en: 2,
    research_zh: 3,
    research_en: 4,
    download_zh: 5,
    download_en: 6,
  },
  /** 添加一对协议的文章*/
  post: {
    agreement_zh: 11,
    agreement_en: 10,
  },
  /** 创建一对用于下载表格的页面*/
  page: {
    download_zh: 5,
    download_en: 6,
  }
}

export const settingsMap = {
  category: {
    "1": "activities_zh",
    "2": "activities_en",
    "3": "research_zh",
    "4": "research_en",
    "5": "download_zh",
    "6": "download_en",
  },
}

export const settingsMapName = {
  category: {
    "1": "团队活动",
    "2": "Team Activities",
    "3": "科学研究",
    "4": "Derived Research",
    "5": "数据下载",
    "6": "Date Download",
  },
}

export const lngSetting = {
  category: {
    "1": "zh",
    "2": "en",
    "3": "zh",
    "4": "en",
    "5": "zh",
    "6": "en",
  },
}

export const URL = {
  base: "http://www.seutraffic.com:9090",
  imgURLModify: (URL) => {
    return "http://www.seutraffic.com:9090" + URL.slice(21)
  }
}

export const img = {
  default: URL.base + "/wp-content/uploads/2021/04/example-1-3.jpg",
  more: URL.base + "/wp-content/uploads/2021/04/example-1-3.jpg",
}

