import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Header, Footer } from '../components/PageFrame'
import { useHistory } from 'react-router-dom'
import DisPlatBoxList from "../components/DisplayBoxList"
import {  useStore } from "../util"
import { getAbstractList, getPostCount, useApi } from '../util/axios-api';
import { Select, Button, Input, Pagination } from 'antd';
import "../style/pages/posts-page.css"
import { idSettings } from '../config';
const { Search } = Input;

const PostsPage = ({ match }) => {
  const history = useHistory()
  const area = match.path.split("/")[1];
  const { t, i18n } = useTranslation("post")
  const { lng, isLogin } = useStore()
  const api = useApi()
  const [list, setList] = useState()
  const [search, setSearch] = useState()
  const [page, setPage] = useState(1)
  const [postCount, setPostCount] = useState()
  const [order, setOrder] = useState("desc")
  const per_page = 12
  const [loading, setLoading] = useState(false)
  const ZH = i18n.language === 'zh'
  const EN = i18n.language === 'en'
  const [category, setCategory] = useState(idSettings.category[match.params.category] || (ZH ? 1 : 2))


  const bugTester = () => {
    console.log(match.params.category);
    console.log("category=", category);
    console.log("pageCnt=", postCount);
    console.log("loading", loading);
    console.log("search", search);
  }

  const loadPageCount = async () => {
    const res = await getPostCount(api, category, search);
    setPostCount(res)
  }

  const loadList = async (t_page) => {
    setLoading(true)
    const res = await getAbstractList(api, { categories: category, per_page, page: t_page || page, search, order })
    setList(res)
    setLoading(false)
  }
  const onSearch = (value) => {
    setSearch(value)
    setPage(1)
    loadPageCount()
    loadList()
  }


  useEffect(() => {
    loadList()
    // bugTester()
  }, [page])

  useEffect(() => {
    loadPageCount()
    setPage(1)
    loadList(1)
    // bugTester()
  }, [order, category])

  useEffect(() => {
    loadPageCount()
    loadList()
    i18n.changeLanguage(lng)
  }, [])

  const setting = {
    history,
    loading,
    data: list,
    titleFontsize: "18",
    contentFontsize: "16",
    admin: isLogin,
  } 

  return (
    <div className="mw-1200">
      <Header area={area} history={history} func={() => setCategory(category % 2 === 0 ? category - 1 : category + 1)}></Header>
      <div className="posts-page">
        <header></header>
        <nav style={{ backgroundColor: "#e7f1ff" }}>
          <Select value={category + ""} onChange={(value) => { setCategory(value) }} loading={loading}>
            {ZH && <Select.Option value={idSettings.category.activities_zh + ""}>{t("Team Activities")}</Select.Option>}
            {EN && <Select.Option value={idSettings.category.activities_en + ""}>{t("Team Activities")}</Select.Option>}
            {ZH && <Select.Option value={idSettings.category.research_zh + ""}>{t("Derived Research")}</Select.Option>}
            {EN && <Select.Option value={idSettings.category.research_en + ""}>{t("Derived Research")}</Select.Option>}
          </Select>
          <Button style={{ marginLeft: "10px" }} loading={loading} onClick={() => { setOrder(order === "asc" ? "desc" : "asc") }}>
            {t(order)}
          </Button>
          <Search className="search" loading={loading} onChange={({ target: { value } }) => { setSearch(value) }} onPressEnter={onSearch} onSearch={onSearch}>

          </Search>
        </nav>
        <main >
          <DisPlatBoxList {...setting}>
          </DisPlatBoxList>
        </main>
        <footer style={{ backgroundColor: "#e7f1ff" }}>
          <Pagination total={postCount} current={page} pageSize={per_page} onChange={(page) => { setPage(page) }}></Pagination>
        </footer>
      </div>
      <Footer></Footer>
    </div>
  )
}
export default PostsPage;
