import axios from 'axios'
import { useSelector } from 'react-redux'
import { message } from 'antd'
import { useDispatch } from "react-redux"
import { img,URL } from '../config'


export const getAbstract = (data) => {
  return data.content.rendered.slice(3, -5)
}

export const getTitle = (data) => {
  return data.title.rendered
}

export const getFigure = (data) => {
  const content = data.figure
  const ans = content.match(/<img [^>]*src=['"]([^'"]+)[^>]*>/i)
  return ans
}

export const examplePicture = () => {
  return URL.base+"/wp-content/uploads/2021/04/example-1-3.jpg"
}

export const getPostURL = (data) => {
  return "/post/" + data.id
}

export const getFigures = (data) => {
  const content = data.figure
  const imgReg = content.match(/src=['"]([^'"]+)[^>]*>/ig)
  const ans = imgReg?.map((item) => {
    return item.slice(5, -3)
  })
  return ans
}





/**
 * @description 用于一切的内容请求，其中保存了 apiToken 的信息 缺乏对apiToken 的合法性检查
 * @param {*} headers  自定义请求头
 * @returns 
 */

export const useApi = (headers) => {
  let { apiToken } = useSelector((state) => state, (left, right) => left.apiToken === right.apiToken)
  return axios.create({
    baseURL: URL.base+'/wp-json/wp/v2/',
    headers: {
      'Authorization': apiToken ? "Bearer " + apiToken : "",
      ...headers
    },
    transformResponse(data) {
      const parsedData = JSON.parse(data)
      return parsedData
    }
  })
}

/**
 * @description 用于用户登录的api
 * @returns 
 */
export const loginApi = () => {
  return axios.create({
    baseURL: URL.base+"/wp-json/jwt-auth/v1/token",
    transformResponse(data) {
      const parsedData = JSON.parse(data)
      return parsedData
    }
  })
}

/**
 * 用于对部分错误进行反馈处理
 * @param {*} error 
 */
export const errorHandler = (error) => {

}

/**
 * 套在网络请求外层用于结果获取前的异常处理
 * @param {*} func 
 * @param {*} successMessage  成功时的反馈
 * @param {*} errorMessage  失败时的反馈 默认为 error.response.data.message
 * @returns 
 */
export const apiWrapper = async (func, successMessage, errorMessage) => {
  try {
    const res = await func()
    res.success = true
    if (successMessage) message.success(successMessage)
    return res
  } catch (error) {
    const res = error.response
    errorHandler(error.response)
    message.error(
      <span dangerouslySetInnerHTML={{ __html: errorMessage || error.response?.data.message || error.message }} />
    )
    if (res) res.success = false
    return res
  }
}

/**
 * @description 媒体上传接口
 * @param {*} api 
 * @param {*} file 需要上传的媒体
 * @returns {data,status,success}
 */
export const uploadMedia = async (api, file) => {
  const checkType = file.type === 'image/jpeg' || file.type === 'image/png'
  if (!checkType) {
    message.error('只允许上传 JPG/PNG 文件!')
    return null
  }
  const isLt5M = file.size / 1024 / 1024 < 5
  if (!isLt5M) {
    message.error('图片文件不得大于 5MB!')
    return null
  }
  const formData = new FormData()
  formData.append('file', file)
  const res = await apiWrapper(() => api.post("media", formData))
  return res
}

/**
 * @description 获取最新媒体资源
 * @param {*} api 
 * @returns {[{id,title,type,url}]}
 */
export const getMediaList = async (api) => {
  const res = await apiWrapper(() => api.get("media"))
  const list = res.data.map((item) => {
    return {
      id: item.id,
      title: item.name,
      type: 'IMAGE',
      url: item.guid.rendered
    }
  })
  return list
}

/**
 * @abstract 获取摘要块
 * @param {*} api 
 * @param {*} params 
 * @returns 
 */
export const getAbstractList = async (api, params, successMessage, errorMessage) => {
  const res = await apiWrapper(() => api.get("posts", { params }), successMessage, errorMessage)
  if (!res?.success) {
    return
  }
  const list = res.data.map((item) => {
    let figure = getFigure(item)
    if (Array.isArray(figure)) {
      figure = figure[1]
    } else {
      figure = img.default
    }
    return {
      id: item.id,
      title: getTitle(item),
      content: getAbstract(item),
      imgUrl: figure,
      url: getPostURL(item)
    }
  })
  return list
}

/**
 * @description 用于post的上传
 * @param {*} api 
 * @param {*} id 
 * @param {*} data 
 * @returns 
 */
export const postPost = async (api, data, id, successMessage, errorMessage) => {
  const res = await apiWrapper(() => api.post("posts/" + (id ? id : ""), data), successMessage || "上传成功", errorMessage)
  return res
}

/**
 * @description 用于post的删除
 * @param {*} api 
 * @param {*} id 
 * @returns 
 */
export const deletePost = async (api, id, successMessage, errorMessage) => {
  const res = await apiWrapper(() => api.delete("posts/" + id), successMessage || "删除成功", errorMessage)
  return res
}
/**
 * @description 用于post的获取
 * @returns 
 */
export const getPost = async (api, id, successMessage, errorMessage) => {
  const res = await apiWrapper(() => api.get("posts/" + id), successMessage, errorMessage)
  return res
}

export const getDownloadList = async (api, params, successMessage, errorMessage) => {
  const res = await apiWrapper(() => api.get("posts", { params }), successMessage, errorMessage)
  const list = res.data.map((item) => {
    const data = item.data && JSON.parse(item.data)
    return {
      id: item.id,
      title: getTitle(item),
      content: getAbstract(item),
      imgUrl: getFigures(item),
      ...data
    }
  })
  return list
}


/**
 * 查询某种种类文章的数量
 * @param {*} api 
 * @param {*} categories 需要查询的种类
 * @param {*} successMessage 
 * @param {*} errorMessage 
 * @returns 
 */
export const getPostCount = async (api, categories, search, successMessage, errorMessage) => {
  const per_page = 100;
  let page = 1, num = 0;
  while (true) {
    const { success, data } = await apiWrapper(() => api.get("posts", { params: { categories, per_page, page, search } }), successMessage, errorMessage);
    if (success) {
      num += data.length
      page++
      if (data.length < 100) {
        return num
      }
    } else {
      return num
    }
  }
}


export const loginRequest = async (api, data, successMessage, errorMessage) => {
  const res = await apiWrapper(() => api.post("", data), successMessage, errorMessage)
  return res
}

export const getPageData = async (api, params, successMessage, errorMessage) => {
  const res = await apiWrapper(() => api.get("pages/" + params.id, { params }), successMessage, errorMessage)
  const list = res?.data?.data === "" ? [] : JSON.parse(res?.data?.data)
  return list
}

export const updatePageData = async (api, params, successMessage, errorMessage) => {
  const res = await apiWrapper(() => api.post("pages/" + params.id, params), successMessage, errorMessage)
  return res
}