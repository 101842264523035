import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Footer, Header } from "../components/PageFrame";
import "../style/pages/home.css"
import TopTitleZH from "../asset/img/home/top-title-zh.png"
import TopTitleEN from "../asset/img/home/top-title-en.png"
import { useHistory } from 'react-router-dom'
import { useStore } from "../util"
import "../style/icon/home/iconfont.css"
import Logo1 from "../asset/img/home/logo1.png"
import Logo2 from "../asset/img/home/logo2.png"
import Logo3 from "../asset/img/home/logo3.png"
import Logo4 from "../asset/img/home/logo4.png"
import Logo5 from "../asset/img/home/logo5.png"
import Logo6 from "../asset/img/home/logo6.png"
import Logo7 from "../asset/img/home/logo7.png"
import Logo8 from "../asset/img/home/logo8.png"
import LogoBase from "../asset/img/logo-base.png"
import LogoEye from "../asset/img/logo-eye.png"


const Home = () => {
  const history = useHistory()
  const { t, i18n } = useTranslation("home")
  const { lng } = useStore();
  const ZH = i18n.language === "zh"
  const EN = i18n.language === "en"
  useEffect(() => {
    i18n.changeLanguage(lng)
  }, [])

  return (
    <div className="mw-1200">
      <Header area={"home"} history={history} />
      <div className="home-page">
        <header className="top-wrapper">
          <div className="top w-1280-r">
            <section className="top-left-wrapper">
              <img src={EN ? TopTitleEN : TopTitleZH} alt="loge" draggable={false} style={{ alignSelf: "center" }} />
              <div className="logo-wrapper" style={{ alignSelf: "center",padding:"25px"}}>
                <img className="logo-base" src={LogoBase} width="250" height="250" alt=""  draggable={false} />
                <img className="logo-eye" src={LogoEye} width="250" height="250" alt="" style={{ alignSelf: "center" }} draggable={false} />
              </div>
              <div className="FW COL-W FS-24" style={{ alignSelf: "center" }}>{EN ? (<div>Ubiquitous Traffic Eye,<br />in anywhere you need.</div>) : (<div>无处不在的交通眼，<br />为你所需尽我所能。</div>)}</div>
            </section>

            <section className="top-right-wrapper">
              <ul className="box-list">

                <li className="box">
                  <div className="content-a content">10</div>
                  <div className="text"> {t("top-data-3")}</div>
                </li>

                <li className="box">
                  <div className="content">{"95"}<div className="rb"><div>+</div><div>%</div> </div>
                  </div>
                  <div className="text">{t("top-data-7")}</div>
                </li>

                <li className="box">

                  {EN && <div className="content-b">Thousands</div>}

                  {ZH && <div style={{ color: "#fff" }}>
                    <span>上</span>
                    <span style={{ fontSize: "3em" }}>千</span>
                  </div>}

                  <div className="text">{t("top-data-9")}</div>
                </li>
              </ul>

              <section>
                <p className="td-2 FS COL-W">
                  {t("top-paragraph-1")}
                </p>
              </section>

            </section>
          </div>
        </header>


        <section className="introduce">
          <div className="w-1280 content">
            <div className="FW FS-32" style={{ color: "#6187b6", marginBottom: "15px" }}>{t("Our-data")}</div>
            <div className="text td-2">{t("Out-data-text1")}</div>
            <div className="intro-item-list">
              <div className="intro-item">
                <i className="iconfont icon-duoweidu"></i>
                <div className="des">{t("label1-1")}</div>
              </div>
              <div className="intro-item">
                <i className="iconfont icon-mubiao"></i>
                <div className="des">{t("label1-2")}</div>
              </div>
              <div className="intro-item">
                <i className="iconfont icon-lianjie"></i>
                <div className="des">{t("label1-3")}</div>
              </div>
              <div className="intro-item">
                <i className="iconfont icon-wendingxingqiang"></i>
                <div className="des">{t("label1-4")}</div>
              </div>
              <div className="intro-item">
                <i className="iconfont icon-sanweizuobiao"></i>
                <div className="des">{t("label1-5")}</div>
              </div>

            </div>
            <div className="test td-2">{t("Out-data-text2")}</div>
            <div className="info-wrapper">
              <div className="logo-item-list">
                <div className="logo-item">
                  <i className="iconfont icon-Field-number"></i>
                  <div className="des">{t("label2-1")}</div>
                </div>
                <div className="logo-item">
                  <i className="iconfont icon-weizhi"></i>
                  <div className="des">{t("label2-2")}</div>
                </div>
                <div className="logo-item">
                  <i className="iconfont icon-lane-shift"></i>
                  <div className="des">{t("label2-3")}</div>
                </div>
                <div className="logo-item">
                  <i className="iconfont icon-quanping_huaban1"></i>
                  <div className="des">{t("label2-4")}</div>
                </div>
                <div className="logo-item">
                  <i className="iconfont icon-sudu"></i>
                  <div className="des">{t("label2-5")}</div>
                </div>
                <div className="logo-item">
                  <i className="iconfont icon-shijian"></i>
                  <div className="des">{t("label2-6")}</div>
                </div>
                <div className="logo-item">
                  <i className="iconfont icon-juli"></i>
                  <div className="des">{t("label2-7")}</div>
                </div>
                <div className="logo-item">
                  <i className="iconfont icon-jiasudu"></i>
                  <div className="des">{t("label2-8")}</div>
                </div>

              </div>
              <div className="ms-item-list">
                <div className="ms-item">
                  <img src={Logo1} alt="logo" draggable={false} />
                  <div className="des">{t("label2-9")}</div>
                </div>
                <div className="ms-item">
                  <img src={Logo2} alt="logo" draggable={false} />
                  <div className="des">{t("label2-10")}</div>
                </div>
                <div className="ms-item"></div>
              </div>
            </div>
            <div className="test td-2">{t("Out-data-text3")}</div>
            <div className="mt-item-list">
              <div className="mt-item">
                <img src={Logo3} alt="logo" draggable={false} />
                <div className="des">{t("label3-1")}</div>
              </div>
              <div className="mt-item">
                <img src={Logo4} alt="logo" draggable={false} />
                <div className="des">{t("label3-2")}</div>
              </div>
              <div className="mt-item">
                <img src={Logo5} alt="logo" draggable={false} />
                <div className="des">{t("label3-3")}</div>
              </div>
            </div>
            <div className="test td-2">{t("Out-data-text4")}</div>

            <div className="mpp-item-list">
              <div className="mp-item">
                <img src={Logo6} alt="logo" draggable={false} />
                <div className="des">{t("label4-1-1")}</div>
                <div className="des">{t("label4-1-2")}</div>
                <div className="des">{t("label4-1-3")}</div>
                <div className="des">{t("label4-1-4")}</div>
                <div className="des">{t("label4-1-5")}</div>
                <div className="des">{t("label4-1-6")}</div>
              </div>
              <div className="mp-item">
                <img src={Logo7} alt="logo" draggable={false} />
                <div className="des">{t("label4-2-1")}</div>
                <div className="des">{t("label4-2-2")}</div>
                <div className="des">{t("label4-2-3")}</div>
                <div className="des">{t("label4-2-4")}</div>
              </div>
              <div className="mp-item">
                <img src={Logo8} alt="logo" draggable={false} />
                <div className="des">{t("label4-3-1")}</div>
                <div className="des">{t("label4-3-2")}</div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  )
}

export default Home