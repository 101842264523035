import React, { useEffect, useState } from 'react';
import "../style/components/message-board.css"
import MailLogo from '../asset/img/message-board/mail.jpg'
import { Col, Input, Tooltip } from 'antd';
import { useTranslation } from "react-i18next";
import { useStore } from "../util"
import Code from "../asset/img/about/code.jpg"
const { TextArea } = Input;

const MessageBoard = ({ area }) => {
  const { t, i18n } = useTranslation(area)
  const { lng } = useStore()
  useEffect(() => {
    i18n.changeLanguage(lng)
  }, [])
  const ZH = i18n.language === "zh"
  return (
    <div className="message-board">
      <div className="top">
        <img src={MailLogo} alt="" />
        <div className="title">
          <h1>{t("CU-1")}<br /> {t("CU-1-1")}</h1>
        </div>
      </div>
      {/* <TextArea style={{ width: "950px", height: "160px", backgroundColor: "#eeeeee", resize: 'none', margin: "10px 0 35px" }} />
      <h2>{t("CU-2")}</h2>
      <Input style={{ width: "400px", backgroundColor: "#eeeeee" }} /> */}
      <div style={{ display: "flex", justifyContent: "center",alignItems:"center" }}>
        <div style={{ width: "800px" }}>
          <div className="td-2" style={{margin:"10px 0",fontSize:"22px"}}>{ZH ? "欢迎从事轨迹数据分析工作者，关注我们最新动态与数据更新，欢迎加入我们进行互动交流、经验分享、需求沟通，并提出意见建议等。" : "Researchers all over the world are welcome to pay attention to our latest developments and data updates. Welcome to join us for interactive exchanges, experience sharing, demand communication, and suggestions."}</div>
          <div className="td-2" style={{margin:"10px 0", fontSize:"22px",fontWeight:"bold",color:"#194477"}}>{ZH ? "微信公众号：UbiquitousTrafficEye" : "WeChat public account: UbiquitousTrafficEye"}</div>
          <div className="td-2" style={{margin:"10px 0",fontSize:"22px",fontWeight:"bold",color:"#194477"}}>{ZH ? "联系邮箱：UbiquitousTrafficEye@outlook.com" : "E-mail address: UbiquitousTrafficEye@outlook.com"}</div>
        </div>
        <Tooltip title={ZH ? "扫码关注：UbiquitousTrafficEye" : `Scan the code to follow UbiquitousTrafficEye `}>
          <img src={Code} alt="Code" />
        </Tooltip>
      </div>
    </div>
  )
}

export default MessageBoard