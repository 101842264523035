import { message } from 'antd';
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {useStore} from "../util"
function AuthRoute(props) {
  const {isLogin}=useStore()
  // 如果用户有权限，就渲染对应的路由
  if (isLogin) {
    return <Route{...props}/>
  } else {
    // 如果没有权限，返回配置的默认路由
    message.warning("无访问权限！")
    return <Redirect to={"/error/403"} />
  }
}
export default AuthRoute;