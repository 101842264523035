import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Header, Footer } from '../components/PageFrame'
import "../style/pages/customize.css"
import { useHistory } from 'react-router-dom'
import { useStore } from "../util"
import LogoIdea from "../asset/img/customize/logo-idea.png"
import LogoVideo from "../asset/img/customize/logo-video.png"
import LogoInfo from "../asset/img/customize/logo-info.png"
import LogoMail from "../asset/img/customize/logo-mail.png"
import { SectionTitle } from "../components"
import { Tooltip } from 'antd';
import { idSettings } from '../config';
const Customize = ({ match }) => {
  const history = useHistory()
  const area = match.path.split("/")[1];
  const { t, i18n } = useTranslation("customize")
  const { lng } = useStore()
  const ZH = i18n.language === 'zh'

  useEffect(() => {
    i18n.changeLanguage(lng)
  }, [])

  const jumpToAgreement = () => {
    ZH ? history.push("/post/" + idSettings.post.agreement_en) : history.push("/post/" + idSettings.post.agreement_en);  /** 配置项 */
  }

  return (
    <div className="mw-1200">

      <Header area={area} history={history}></Header>
      <div className="customize">

        <header className="bg-top ">
          <div className="w-1280-r top-wrapper">

            <div className="title FW FS-48 COL-W">{t("top-title")}</div>
            <div className="des td-2 COL-W FS-18 mw-950" style={{ width: "950px" }}>{t("top-A")}</div>
            {
              ZH ? <div className="des td-2 COL-W FS-18" style={{ width: "950px" }}>
                <span>您选择共享数据服务，将默认您会认同我们的数据</span><Tooltip placement="bottom" title="查看协议"><span onClick={() => { jumpToAgreement() }} className="HV-CP" style={{ color: "#8cfec5" }}>共享服务协议</span></Tooltip><span>。</span>
              </div> : <div className="des td-2 COL-W FS-18" style={{ width: "950px" }}>
                <span>If you choose to share data service, you will agree to our</span><Tooltip placement="bottom" title="check agreement"><span onClick={() => { jumpToAgreement() }} className="HV-CP" style={{ color: "#8cfec5" }}> data sharing service agreement </span></Tooltip><span>by default.</span>
              </div>
            }
          </div>
        </header>


        <div className="body y w-1280" style={{ paddingTop: "25px" }}>
          <SectionTitle logo={LogoIdea} title={t("ins-title")}></SectionTitle>
          <div className="content-box bg-body">
            <ul className="content-list NAR">
              <li className="content-item">
                <div className="title">{t("DC-T")}</div>
                <div className="des td-2">{t("DC-A")}</div>
              </li>
              <li className="content-item">
                <div className="title">{t("IC-T")}</div>
                <div className="des td-2">{t("IC-A")}</div>
              </li>
              <li className="content-item">
                <div className="title">{t("NI-T")}</div>
                <div className="des td-2">{t("NI-A-1")}</div>
                <div className="des ">{t("NI-A-2")}</div>
                <div className="des">{t("NI-A-3")}</div>
                <div className="des">{t("NI-A-4")}</div>
                <div className="des">{t("NI-A-5")}</div>
                <div className="des">{t("NI-A-6")}</div>
              </li>
            </ul>
            <ul className="logo-list">
              <li className="logo">
                <img src={LogoVideo} alt="" draggable={false} />
              </li>
              <li className="logo">
                <img src={LogoInfo} alt="" draggable={false} />
              </li>
              <li className="logo">
                <img src={LogoMail} alt="" draggable={false} />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}
export default Customize;
