import React from 'react'
import { HashRouter, Switch } from 'react-router-dom'
import home from "../pages/Home"
import Route from "../components/NRoute"
import applications from "../pages/Applications";
import about from "../pages/About"
import download from "../pages/Download";
import customize from "../pages/Customize";
import ScrollToTop from '../components/ScrollToTop'
import postEditor from "../pages/PostEditor"
import AuthRoute from "../components/AuthRoute"
import post from "../pages/Post"
import { Page404, Page403 } from "../pages/error"
import PostsPage from "../pages/PostsPage"
const RootRouter = () => {
  return (
    <HashRouter>
      <ScrollToTop>
        <Switch>
          <Route exact path="/" component={home}></Route>
          <Route path="/home" component={home}></Route>
          <Route path="/applications" component={applications}></Route>
          <Route path="/about" component={about}></Route>
          <Route path="/download" component={download}></Route>
          <Route path="/customize" component={customize}></Route>
          <AuthRoute path="/post/editor/:id" component={postEditor}></AuthRoute>
          <AuthRoute path="/post/editor/" component={postEditor}></AuthRoute>
          <Route path="/post/list/:category" component={PostsPage}></Route>
          <Route path="/post/list" component={PostsPage}></Route>
          <Route path="/post/:id" component={post}></Route>
          <Route path="/error/403" component={Page403}></Route>
          <Route path="/" component={Page404}></Route>
        </Switch>
      </ScrollToTop>
    </HashRouter>
  )
}

export default RootRouter
