import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Header, Footer } from '../components/PageFrame'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useStore } from "../util"
import { useApi } from '../util/axios-api';
import { Result, Button } from 'antd';
export const Page404 = ({ match }) => {
  const history = useHistory()
  const area = match.path.split("/")[1];
  const { t, i18n } = useTranslation("area")
  const { lng } = useStore()
  const api = useApi()
  const ZH = i18n.language === "zh"
  const EN = i18n.language === "en"
  useEffect(() => {
    i18n.changeLanguage(lng)
  }, [])
  return (
    <div className="mw-1200">
      <Header area={area} history={history}></Header>
      <div style={{height:"71vh",display:"flex",alignItems:"center",justifyContent:"center"}}>
        <Result
          status="404"
          title="404"
          subTitle={ZH ? "对不起，您访问的页面不存在。" : "Sorry, the page you visited does not exist."}
          extra={<Button type="primary" onClick={() => { history.replace("/") }}>{ZH ? "返回首页" : "Back Home"}</Button>}
        />
      </div>
      <Footer></Footer>
    </div>
  )
}

export const Page403 = ({ match }) => {
  const history = useHistory()
  const area = match.path.split("/")[1];
  const { t, i18n } = useTranslation("area")
  const { lng } = useStore()
  const api = useApi()
  const ZH = i18n.language === "zh"
  const EN = i18n.language === "en"
  useEffect(() => {
    i18n.changeLanguage(lng)
  }, [])
  return (
    <div>
      <Header area={area} history={history}></Header>
      <div style={{height:"71vh",display:"flex",alignItems:"center",justifyContent:"center"}}>
        <Result
          status="403"
          title="403"
          subTitle={ZH ? "对不起，您没有权限访问此页面。" : "Sorry, you are not authorized to access this page."}
          extra={<Button type="primary" onClick={() => { history.replace("/") }}>{ZH ? "返回首页" : "Back Home"}</Button>}
        />
      </div>
      <Footer></Footer>
    </div>
  )
}