import React from 'react';
import "../style/components/index.css"
/**
 * @description 段间标题
 * @returns 
 */
export const SectionTitle = ({ logo, title }) => {
  return (
    <div className="section-title w-1280">
      <div className="logo-wrapper SAR">
        <img src={logo} alt="logo" draggable={false} />
        <div className="line" />
        <div className="FS-32 FW-B LH-2 title ">{title}</div>
      </div>
    </div>
  )
}
