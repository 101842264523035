import 'braft-editor/dist/index.css'
import React, { useState, useEffect } from 'react'
import BraftEditor from 'braft-editor'
import "../style/components/rich-editor.css"
import { getMediaList, uploadMedia } from '../util/axios-api';
import { URL } from '../config';

/**
 * @description 富文本编辑器
 * @param {{ api, value, onChange, type:"mini or null" }} 
 */
const Editor = ({ api, value, onChange, type }) => {
  const [editorState, setEditorState] = useState(BraftEditor.createEditorState(value))
  const [mediaItems, setMediaItems] = useState([])
  const myUploadFn = (param) => {
    const { file } = param
    uploadMedia(api, file).then((res) => {
      if (res?.success) {
        param.success({
          url: URL.imgURLModify(res.data.guid.raw),
          meta: { id: "1", title: file.name }
        })
      } else {
        param.error({})
      }
    })
  }
  const loadMediaList = async () => {
    const res = await getMediaList(api)
    setMediaItems(res)
  }

  useEffect(() => {
    if (editorState.toHTML() !== value) {
      setEditorState(BraftEditor.createEditorState(value));
    }
  }, [value])

  const handleChange = (editorState) => {
    setEditorState(editorState);
    onChange?.(editorState.toHTML())
  }

  useEffect(() => {
    loadMediaList()
  }, [])

  const excludeControls = [
    "undo",
    "redo",
    "blockquote",
    'line-height',
    'letter-spacing',
    "code",
    'clear',
    'headings',
    'remove-styles',
    'superscript',
    'subscript',
  ]

  const onlyMedia = [
    'undo', 'redo', 'separator',
    'font-size', 'line-height', 'letter-spacing', 'separator',
    'text-color', 'bold', 'italic', 'underline', 'strike-through', 'separator',
    'superscript', 'subscript', 'remove-styles', 'emoji', 'separator', 'text-indent', 'text-align', 'separator',
    'headings', 'list-ul', 'list-ol', 'blockquote', 'code', 'separator',
    'link', 'separator', 'hr', 'separator',
    'separator', 'clear', "fullscreen"
  ]

  return (
    < div className="editor-wrapper" >
      <BraftEditor
        value={editorState}
        onChange={handleChange}
        excludeControls={type === "mini" ? onlyMedia : excludeControls}
        contentStyle={type === "mini" ? { height: 300 } : { height: 900 }}
        media={{ uploadFn: myUploadFn, items: mediaItems }}
      />
    </div >
  )
}

export default Editor