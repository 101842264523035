import React from 'react';
import "../style/components/display-box-list.css"
import { Skeleton } from 'antd';
import { SectionTitle } from "./index"
import i18next from 'i18next';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Button } from "antd"
const defaultData = [
  {
    id: "1",
    title: "Loading...",
    content: "Loading...",
  },
  {
    id: "2",
    title: "Loading...",
    content: "Loading...",
  },
  {
    id: "3",
    title: "Loading...",
    content: "Loading...",
  },
  {
    id: "4",
    title: "Loading...",
    content: "Loading...",
  },
  {
    id: "5",
    title: "Loading...",
    content: "Loading...",
  },
  {
    id: "6",
    title: "Loading...",
    content: "Loading...",
  },
]
/**
 * 
 * @param  item  
 * @param  loading  
 * @param  history  
 * @returns 
 */
const DisplayBox = ({ item, loading, history, width = "325px", height = "240px", titleFontsize = "24", contentFontsize = "16" }) => {
  const st = {
    backgroundImage: loading ? undefined : 'url(' + item.imgUrl + ')',
    width,
    height
  }
  return (
    <ul className="display-box" style={st} onClick={() => { history.push(item.url) }}>
      <div className="mask">
        {loading ? <Skeleton active /> :
          <>
            <div className={"title FW COL-W FS-" + titleFontsize}>{item.title}</div>
            <div className={"content COL-W FS-" + contentFontsize}>{item.content}</div>
          </>
        }
      </div>
    </ul>)
}

/**
 * 
 * @param history
 * @param loading
 * @param title
 * @param logo 可选
 * @param data 有默认加载值
 * @returns 
 */
const DisPlatBoxList = ({ admin, history, loading, title, logo, data = defaultData, width, height, titleFontsize, contentFontsize }) => {
  const ZH = i18next.language === "zh"
  const settings = {
    width,
    height,
    titleFontsize,
    contentFontsize,
  }
  return (
    <div className="display-box-list">
      {
        logo &&
        <div className="dpl-header">
          <SectionTitle title={title} logo={logo}></SectionTitle>
        </div>
      }
      <div className="dpl-body">

        <div className="w-1280">
          <li className="box-list">
            {
              data?.map(item => {
                return <DisplayBox {...settings} item={item} loading={loading} history={history} key={item.id}></DisplayBox>
              })
            }
          </li>
        </div>
        {admin && <div style={{ display: "flex", justifyContent: "center", margin: "30px 30px" }}>
          <Button type="primary" onClick={() => history.push("/post/editor")} size={"large"} >
            <PlusCircleOutlined />{ZH ? "添加新文章" : "Add a new article"}
          </Button>
        </div>}
      </div>
    </div>
  )
}

export default DisPlatBoxList