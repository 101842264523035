import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom'
import { Footer, Header } from '../components/PageFrame'
import { useSelector } from 'react-redux'
import Logo6 from '../asset/img/applications/logo6.png'
import DisPlatBoxList from "../components/DisplayBoxList"
import "../style/pages/applications-page.css"
import { getAbstractList, useApi } from '../util/axios-api';
import { idSettings, img } from '../config';
const ApplicationsPage = ({ match }) => {
  const area = match.path.split("/")[1];
  const api = useApi()
  const history = useHistory();
  const { t, i18n } = useTranslation("Applications")
  const { lng, isLogin } = useSelector((state) => state)
  const [boxList, setBoxList] = useState(undefined)
  const [loading, setLoading] = useState(false)

  const loadList = async (lng) => {
    setLoading(true)
    const categories = lng === "zh" ? idSettings.category.research_zh : idSettings.category.research_en
    const box = await getAbstractList(api, { per_page: 9, categories: categories });
    setBoxList(box)
    box.push({
      id: "0",
      title: "get more...",
      content: "know more about it...",
      url: "/post/list/research_" + i18n.language,
      imgUrl:"http://www.seutraffic.com:9090/wp-content/uploads/2021/05/moro.jpg",
    })
    setLoading(false)
  }

  useEffect(() => {
    loadList(i18n.language)
    i18n.changeLanguage(lng)
  }, [])

  return (
    <div className="mw-1200">
      <Header area={area} history={history} reload={loadList} />
      <div className="products-page">
        <div className="top-bg">
          <div className="w-1280-r top-wrapper">
            <div className="FS-48 FW COL-W">{t("top-title")}</div>
            <section className="mw-950" style={{ width: "950px" }}>
              <div className="td-2 FS-18 COL-W">{t("TT1")}</div>
              <div className="td-2 FS-18 COL-W">{t("TT2")}</div>
            </section>
          </div>
        </div>
        <div className="body">
          <DisPlatBoxList admin={isLogin} title={<div style={{ color: "#fefeff" }}>{t("D-R-title")}</div>}
            logo={Logo6} history={history} loading={loading} data={boxList}
            titleColor={"#fefeff"} height="320px" width="490px" />
        </div>
      </div>
      <Footer i18={i18n} />
    </div>
  )
}

export default ApplicationsPage
